/*Start - src/assets/css/root.css*/
:root{
--teste00:rgba(255,0,0,0);
--teste01:rgba(255,0,0,0.1);
--teste02:rgba(255,0,0,0.2);
--teste03:rgba(255,0,0,0.3);

--preto_lente01:rgba(0,0,0,0.1);
--preto_lente02:rgba(0,0,0,0.2);
--preto_lente03:rgba(0,0,0,0.3);
--preto_lente04:rgba(0,0,0,0.4);
--preto_lente05:rgba(0,0,0,0.5);
--preto_lente06:rgba(0,0,0,0.6);
--preto_lente07:rgba(0,0,0,0.7);
--preto_lente08:rgba(0,0,0,0.8);
--preto_lente09:rgba(0,0,0,0.9);

--branco_lente01:rgba(255,255,255,0.1);
--branco_lente02:rgba(255,255,255,0.2);
--branco_lente03:rgba(255,255,255,0.3);
--branco_lente04:rgba(255,255,255,0.4);
--branco_lente05:rgba(255,255,255,0.5);
--branco_lente06:rgba(255,255,255,0.6);
--branco_lente07:rgba(255,255,255,0.7);
--branco_lente08:rgba(255,255,255,0.8);
--branco_lente09:rgba(255,255,255,0.9);

--transp:rgba(0,0,0,0);

--facebook:rgb(50, 82, 159);
--linkedin:rgb(0, 119, 181);
--twitter:rgb(29, 161, 242);
--whatsapp:rgb(37, 211, 102);

--branco235:rgba(235,235,245,1);

--cinza26:rgba(26, 26, 26, 1);
--cinza33:rgba(33, 37, 41, 1);
--cinza100:rgb(100, 100, 110);
--cinza120:rgb(120, 120, 130);
--cinza155:rgb(155, 155, 165);
--cinza205:rgb(205, 205, 215);
--cinza222:rgb(222, 222, 232);
--cinza245:rgb(245, 245, 255);
--cinza249:rgb(249, 249, 255);

--amarelo_itajai:rgb(255, 204, 0);
--amarelo_itajai02:rgb(235, 184, 0);
--amarelo_itajai03:rgb(205, 154, 0);

--azul_itajai: rgb(0, 0, 139);
--azul_itajai02:rgb(10, 10, 90);
--azul_itajai03:rgb(46, 38, 132);
--azulConectaiFundo:rgb(18, 21, 46);
--azulConectaiFonte:rgb(92, 224, 226);
--azul_lente:rgba(10, 10, 90, 0.7);

--cardVerde:rgba(98, 169, 168, 1);
--cardAzul:rgba(139, 156, 180, 1);

--fonte:rgba(34,34,34,1);

--segoe:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
--roboto:"Roboto", sans-serif;
--montserrat:"Montserrat", sans-serif;

}
/*End - src/assets/css/root.css*/