/*Start - src/components/ListFiles/style.css*/
.ListFiles{
width:100%;
background-color:var(--teste00);
font-family:var(--roboto);
margin-bottom:1vw;
}
.ListFiles-itens{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
}
.ListFiles-item{
width: 14.4vw;
height: 5vw;
background-color:var(--cinza249);
border-radius: 0.5vw;
margin-right: 0.5vw;
margin-bottom: 0.5vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
padding:0.5vw;
float:left;
transition:all 0.2s ease-in-out;
}
.ListFiles-item:nth-child(4n){
margin-right:0vw;
}
.ListFiles-item:hover{
background-color:var(--azul_itajai);
transition:all 0.2s ease-in-out;
}
.ListFiles-icone{
width:3vw;
height:3vw;
line-height:3vw;
border-radius:3vw;
background-color:var(--azul_itajai);
float:left;
margin-top:0.5vw;
margin-bottom:0vw;
margin-left:0vw;
margin-right:0.3vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
text-align: center;
transition:all 0.2s ease-in-out;
}
.ListFiles-icone svg{
height:1.3vw;
fill:white;
transition:all 0.2s ease-in-out;
}
.ListFiles-nome{
width:10vw;
height:100%;
background-color:var(--teste00);
float:left;
box-sizing: border-box;
-webkit-box-sizing: border-box;
display: table;
position: relative;
font-weight:400;
font-size:0.8vw;
line-height:1vw;
color:var(--fonte);
text-align: left;
letter-spacing:0vw;
overflow:hidden;
transition:all 0.2s ease-in-out;
}
.ListFiles-item:hover .ListFiles-nome{
color:white;
transition:all 0.2s ease-in-out;
}
.ListFiles-item:hover .ListFiles-icone{
background-color:white;
transition:all 0.2s ease-in-out;
}
.ListFiles-item:hover .ListFiles-icone svg{
fill:var(--azul_itajai);
transition:all 0.2s ease-in-out;
}
.ListFiles-nome span{
width:100%;
height:100%;
display:block;
display: table-cell;
vertical-align: middle;
overflow:hidden;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.ListFiles{
margin-bottom:3vw;
}
.ListFiles-item{
min-width:auto;
width:100%;
height: 15vw;
border-radius: 1.5vw;
margin-bottom: 1.5vw;
padding:1.5vw;
}
.ListFiles-icone{
width:9vw;
height:9vw;
line-height:9vw;
border-radius:9vw;
margin-top:1.5vw;
margin-right:1.5vw;
}
.ListFiles-icone svg{
height:3.9vw;
}
.ListFiles-nome{
width:75vw;
font-size:4vw;
line-height:4.5vw;
}

}
/*End - src/components/ListFiles/style.css*/