/*Start - src/components/OtherNews/style.css */
.OtherNews{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-bottom: 5vw;
}
.OtherNews-container{
width: 100%;
}
.OtherNews-item{
width: 14.3vw;
background-color:var(--teste00);
float:left;
margin-right:0.7vw;
margin-bottom:0.7vw;
}
.OtherNews-item:nth-child(4n){
margin-right:0vw;
}
.OtherNews-item img{
width:100%;
height:9.4vw;
border-radius:0.5vw;
}
.OtherNews-icone{
width: 13vw;
height: 9.4vw;
display: flex;
background-color: var(--azul_itajai02);
border-radius:0.5vw;
align-items: center;
justify-content: center;
}
.OtherNews-icone svg{
height:5vw;
fill:white;
color:white;
}
.OtherNews-icone.empty{
width:100%;
background-repeat:no-repeat;
background-position:center center;
background-size:auto 7vw;
}
.OtherNews-col{
display: flex;
flex: 1;
flex-direction: column;
justify-content: space-between;
}
.OtherNews-titulo{
width:100%;
height:4vw;
color:var(--cinza100);
font-style: normal;
font-weight: 700;
font-size: 0.8vw;
line-height:0.9vw;
margin-top:0.5vw;
background-color:var(--teste00);
}
.OtherNews-data{
color:var(--cinza100);
font-weight: 400;
font-style: normal;
font-size: 0.7vw;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.OtherNews{
margin-bottom: 15vw;
}
.OtherNews-container{
display:block;
margin-top:5vw;
}
.OtherNews-item{
width: 100%;
margin-bottom:5vw;
margin-right:0vw;
}
.OtherNews-item img{
height:60vw;
border-radius:1.5vw;
}
.OtherNews-icone{
width:100%;
height:60vw;
border-radius:1.5vw;
}
.OtherNews-icone svg{
height:15vw;
}
.OtherNews-icone.empty{
background-size:auto 40vw;
}
.OtherNews-col{
}
.OtherNews-titulo{
font-size:4vw;
line-height:5vw;
margin-top:1.5vw;
height:auto;
}
.OtherNews-data{
font-size:3vw;
}

}
/*End - src/components/OtherNews/style.css */
