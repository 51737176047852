/*Start - src/components/Popup/style.css*/
.Popup{
display:none;
}
.Popup-escurecer{
width:100%;
height:100%;
background-color:var(--preto_lente07);
position:fixed;
z-index:999;
top:0vw;
left:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
}
.Popup-escurecer-fechar{
width:3vw;
height:3vw;
background-color:var(--teste00);
position:absolute;
right:1vw;
top:1vw;
line-height:3vw;
text-align: center;
}
.Popup-escurecer-fechar svg{
height:2vw;
fill:var(--cinza222);
}
.Popup-item{
width:50vw;
height:90vh;
position: fixed;
left:25vw;
top: 5vh;
z-index:9999;
background-color:white;
border-radius:0.5vw;
overflow:hidden;
box-sizing: border-box;
-webkit-box-sizing: border-box;
}
.Popup.on{
display:block;
}
.Popup-item.transparente{
background-color:transparent;
}
.Popup-somenteImagem{
height:100%;
margin:0 auto;
display:table;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.Popup-escurecer-fechar{
width:9vw;
height:9vw;
right:3vw;
top:3vw;
line-height:9vw;
}
.Popup-escurecer-fechar svg{
height:6vw;
}
.Popup-item{
width:90vw;
height:80vh;
left:5vw;
top: 10vh;
border-radius:1.5vw;
}
.Popup-somenteImagem{
width:100%;
height:auto;
}

}
/*End - src/components/Popup/style.css*/