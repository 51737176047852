/*Start - src/components/Services/styles.css*/
.Services{
display: flex;
width: 100%;
align-items: center;
justify-content: center;
margin-top: 1.6vw;
}
.Services-container{
width: 60%;
display: flex;
flex-direction: column;
}
.Services-topo{
display: flex;
flex-direction: row;
width: 100%;
height: 4.4vw;
align-items: center;
background-color: var(--amarelo_itajai);
justify-content: space-between;
border-top-left-radius: 0.5vw;
border-top-right-radius: 0.5vw;
}
.Services-btn-titulo{
padding: 0.9vw;
font-family:var(--roboto);
font-size: 1.1vw;
font-style: normal;
font-weight: 700;
text-transform: uppercase;
color:var(--cinza33);
cursor:default;
}
.Services-btn{
height: 100%;
width: 100%;
display: flex;
font-family:var(--roboto);
font-style: normal;
font-weight: 600;
font-size: 0.7vw;
text-transform: uppercase;
text-decoration: none;
align-items: center;
justify-content: center;
cursor: pointer;
color:var(--cinza33);
transition:all 0.2s ease-in-out;
}
.Services-btn.active{
background-color:var(--amarelo_itajai02);
}
.Services-btn:hover{
background-color:var(--amarelo_itajai03);
transition:all 0.2s ease-in-out;
}
.Services-conteudo{
width: 100%;
height: 15.6vw;
background-color:var(--cinza249);
display: flex;
flex-direction: row;
align-items: baseline;
font-family:var(--roboto);
font-size: 0.7vw;
font-style: normal;
font-weight: 500;
justify-content: space-around;
padding-bottom: 1vw;
padding-top: 1vw;
}
.item-container {
display: grid;
width: 100%;
grid-template-columns: repeat(4, 1fr);
gap: 1vw;
list-style-type: none;
}
.Services-conteudo-item{
color:var(--cinza33);
text-decoration:none;
transition:all 0.1s ease-in-out;
}
.Services-conteudo-item:hover{
text-decoration:underline;
padding-left:0.5vw;
transition:all 0.1s ease-in-out;
}
.Services-bottom{
width: 100%;
height: 2.8vw;
line-height: 2.8vw;
display: flex;
flex-direction: row;
align-items: center;
background-color: var(--amarelo_itajai);
justify-content: center;
border-bottom-left-radius: 0.5vw;
border-bottom-right-radius: 0.5vw;
font-size: 0.7vw;
font-family:var(--roboto);
font-style: normal;
font-weight: 700;
text-transform: uppercase;
text-align:center;
overflow:hidden;
transition:all 0.2s ease-in-out;
}
.Services-bottom a{
background-color:var(--teste00);
color:var(--cinza33);
display:block;
width:100%;
height:100%;
text-decoration:none;
}
.Services-bottom a:hover{
text-decoration:underline;
}
.Services-bottom:hover{
background-color:var(--amarelo_itajai02);
transition:all 0.2s ease-in-out;
}
/*End - src/components/Services/styles.css*/