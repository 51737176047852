/*Start - src/components/Search/style.css*/
.FormSearch {
background-image: url(../../assets/imgs/search.png);
backdrop-filter: brightness(60%);
}
.Search{
display: flex;
width: 100%;
align-items: center;
justify-content: center;
background-color:var(--teste00);
}
.Search-container{
width: 60%;
height: 13vw;
display: flex;
flex-direction: column;
gap: 1.6vw;
align-items: center;
justify-content: center;
background-size: cover;
background-repeat: no-repeat;
border-radius: 0.3vw;
position:relative;
overflow:hidden;
background-color:var(--teste00);
background-image:url("../../assets/imgs/search.png");
}
.Search-lente{
width:100%;
height:100%;
position:absolute;
top:0vw;
left:0vw;
background-color:var(--preto_lente04);
}
.Search-container-box{
width: 100%;
height: 100%;
position: absolute;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 3.8vw;
padding-bottom: 3.8vw;
background-color:var(--teste00);
}
.Search-form{
width: 60%;
height: 3vw;
background-color:var(--teste00);
display: flex;
align-items: center;
margin-bottom:1vw;
}
.Search-form input{
width: 90%;
height: 100%;
border: none;
padding-left:0.5vw;
color:var(--fonte);
font-family:var(--roboto);
font-style: normal;
font-size: 0.8vw;
font-weight: 400;
line-height: 3vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
border-top-left-radius:0.3vw;
border-bottom-left-radius:0.3vw;
border-top-right-radius:0vw;
border-bottom-right-radius:0vw;
background-color:white;
backdrop-filter: blur(4px);
-webkit-backdrop-filter: blur(4px);
transition:all 0.2s ease-in-out;
}
.Search-form:hover input{
background-color:var(--branco_lente06);
transition:all 0.2s ease-in-out;
}
.Search-form input::placeholder{
color:var(--fonte);
}
.Search-form button{
width:10%;
height:100%;
box-sizing: border-box;
-webkit-box-sizing: border-box;
background-color:var(--amarelo_itajai);
border:none;
border-top-left-radius:0vw;
border-bottom-left-radius:0vw;
border-top-right-radius:0.3vw;
border-bottom-right-radius:0.3vw;
transition:all 0.2s ease-in-out;
}
.Search-form button svg{
fill:var(--azul_itajai);
height:1.5vw;
transition:all 0.2s ease-in-out;
}
.Search-form:hover button{
background-color:var(--branco_lente04);
backdrop-filter: blur(4px);
-webkit-backdrop-filter: blur(4px);
transition:all 0.2s ease-in-out;
}
.Search-form:hover button svg{
fill:var(--fonte);
transition:all 0.2s ease-in-out;
}
.Search-botoes{
width: 60%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}
.Search-botoes button{
width: 7.6vw;
height: 2.1vw;
border-radius: 0.3vw;
border: 0.1vw solid var(--branco_lente03);
font-size: 0.8vw;
color:var(--fonte);
font-family:var(--roboto);
font-style: normal;
font-weight: 500;
line-height: normal;
background-color:var(--cinza222);
transition:all 0.2s ease-in-out;
}
.Search-botoes button:hover{
background-color:var(--branco_lente03);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
color:white;
transition:all 0.2s ease-in-out;
}
/*End - src/components/Search/style.css*/