/*Start - src/components/Banner/style.css*/
.Banner{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-top: 2vw;
margin-bottom:2vw;
}
.Banner-container{
width:60%;
}
.Banner-img{
border-radius:0.5vw;
}
.carousel-indicators{
background-color:var(--teste00);
margin-bottom:-2vw;
}
.carousel-indicators [data-bs-target]{
background-color:var(--azul_itajai);
width:0.5vw;
height:0.5vw;
}
.carousel-control-next, 
.carousel-control-prev {
background-color: var(--teste00);
width: 2.5vw;
height: 2.5vw;
top: 50%;
transform: translate(0,-50%);
opacity:1;
transition:all 0.2s ease-in-out;
}
.carousel-control-prev {
border-top-left-radius:0vw;
border-top-right-radius:3vw;
border-bottom-left-radius:0vw;
border-bottom-right-radius:3vw;
}
.carousel-control-next {
border-top-left-radius:3vw;
border-top-right-radius:0vw;
border-bottom-left-radius:3vw;
border-bottom-right-radius:0vw;
}
.carousel-control-prev:hover{
background-color: var(--preto_lente05);
transition:all 0.2s ease-in-out;
}
.carousel-control-next:hover{
background-color: var(--preto_lente05);
transition:all 0.2s ease-in-out;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.Banner{
margin-top: 10vw;
margin-bottom:5vw;
}
.Banner-container{
width:93%;
}
.Banner-img{
border-radius:1.5vw;
}
.carousel-indicators{
margin-bottom:-6vw;
}
.carousel-indicators [data-bs-target]{
width:1.5vw;
height:1.5vw;
}
.carousel-control-next, 
.carousel-control-prev {
width: 7.5vw;
height: 7.5vw;
}
.carousel-control-prev {
border-top-right-radius:9vw;
border-bottom-right-radius:9vw;
}
.carousel-control-next {
border-top-left-radius:9vw;
border-bottom-left-radius:9vw;
}

}
/*End - src/components/Banner/style.css*/