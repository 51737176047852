/*Start - src/pages/ListVideos/style.css*/
.pages-ListVideos{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.pages-ListVideos-container{
width: 60%;
background-color:var(--teste00);
margin-top: 0.8vw;
}
.pages-ListVideos-destaque{
width:100%;
background-color:var(--teste00);
margin-bottom:5vw;
}
.pages-ListVideos-destaque-video{
width:100%;
aspect-ratio:16/9;
overflow:hidden;
border-radius:1vw;
}
.pages-ListVideos-destaque-video iframe{
width:100%;
height:100%;
}
.pages-ListVideos-destaque-titulo{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0.5vw;
margin-bottom:1vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
font-weight:600;
font-size:1vw;
line-height:1.3vw;
color:var(--cinza100);
text-align: center;
letter-spacing:0vw;
}
.pages-ListVideos-listagem{
width:100%;
overflow:hidden;
background-color:var(--teste00);
}
.pages-ListVideos-item{
width:32.33%;
height:15vw;
float:left;
margin-right:1.5%;
margin-bottom:1vw;
background-color:var(--teste00);
}
.pages-ListVideos-item:nth-child(3n){
margin-right:0%;
}
.pages-ListVideos-item-video{
width:100%;
aspect-ratio:16/9;
overflow:hidden;
border-radius:0.5vw;
}
.pages-ListVideos-item-video iframe{
width:100%;
height:100%;
}
.pages-ListVideos-item-titulo{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0.5vw;
margin-bottom:0.5vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
font-weight:600;
font-size:0.8vw;
line-height:1vw;
color:var(--cinza100);
text-align: center;
letter-spacing:0vw;
}
.pages-ListVideos-item-capa{
width:100%;
aspect-ratio:16/9;
overflow:hidden;
border-radius:0.5vw;
background-repeat:no-repeat;
background-position:center center;
background-size:cover;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.pages-ListVideos{}
.pages-ListVideos-container{
width:90vw;
margin-top:3vw;
}
.pages-ListVideos-destaque{
margin-bottom:15vw;
}
.pages-ListVideos-destaque-video{
border-radius:3vw;
}
.pages-ListVideos-destaque-video iframe{}
.pages-ListVideos-destaque-titulo{
margin-top:1.5vw;
margin-bottom:3vw;
font-size:5vw;
line-height:8vw;
}
.pages-ListVideos-item{
width:47.5%;
height:50vw;
margin-right:5%;
margin-bottom:3vw;
}
.pages-ListVideos-item:nth-child(3n){
margin-right:5%;
}
.pages-ListVideos-item:nth-child(2n){
margin-right:0%;
}
.pages-ListVideos-item-video{
border-radius:1.5vw;
}
.pages-ListVideos-item-video iframe{}
.pages-ListVideos-item-titulo{
margin-top:1.5vw;
margin-bottom:1.5vw;
font-size:4vw;
line-height:4vw;
}
.pages-ListVideos-item-capa{
border-radius:1.5vw;
}

}
/*End - src/pages/ListVideos/style.css*/