/*Start - src/component/HeaderMobile/style.css*/
.HeaderMobile{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}
.HeaderMobile-container{
width: 93%;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 5vw;
margin-bottom: 5vw;
}
.HeaderMobile-logo{
width:30vw;
}
.HeaderMobile-hamburger{
font-size:7.5vw;
cursor:pointer;
color:var(--azul_itajai02);
}
.HeaderMobile-sidenav-escurecer{
width: 100vw;
height:100vh;
position: fixed;
z-index: 2;
top: 0vw;
left: 0vw;
background-color:var(--preto_lente07);
display:none;
}
.HeaderMobile-sidenav-escurecer.on{
display:block;
}
.HeaderMobile-sidenav{
width: 70vw;
height: 100%;
position: fixed;
z-index: 3;
top: 0vw;
left: -90vw;
background-color:white;
gap: 4vw;
overflow-x: hidden;
transition:all 0.3s ease-in-out;
box-sizing: border-box;
-webkit-box-sizing: border-box;
padding-left: 4vw;
padding-right: 4vw;
padding-top: 4vw;
}
.HeaderMobile-sidenav.on{
left:0vw;
}
.HeaderMobile-sidenav a{
text-decoration: none;
display: block;
transition:all 0.2s ease-in-out;
}
.HeaderMobile-sidenav a:hover {
color:var(--cinza100);
}
.HeaderMobile-sidenav-closebtn{
width:15vw;
height:15vw;
position: absolute;
top: 0vw;
right:4vw;
background-color:var(--teste00);
font-weight:100;
font-size:14vw;
line-height:15vw;
color:var(--azul_itajai02);
text-align: right;
letter-spacing:0vw;
}
.HeaderMobile-sidenav-inicio{
height:13vw;
line-height:13vw;
display: flex;
gap:1vw;
align-items: center;
padding-left: 4vw;
padding-top:0.5vw;
padding-bottom:0.5vw;
margin-top:15vw;
margin-bottom:4vw;
color:var(--azul_itajai) !important;
text-transform: uppercase;
font-weight:700 !important;
font-size:4vw;
text-align: left;
letter-spacing:0vw;
background-color:var(--teste00);
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}
.HeaderMobile-sidenav-inicio svg{
fill:var(--amarelo_itajai);
margin-right:1vw;
margin-top:-1vw;
}
.HeaderMobile-breadcrumb-container{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin-bottom: 4vw;
}
.HeaderMobile-breadcrumb{
width: 93%;
font-weight: 300;
font-size: 2vw;
text-transform: uppercase;
}
.HeaderMobile-sidenav-item{
height:13vw;
line-height:13vw;
display: flex;
gap:1vw;
align-items: center;
padding-left: 4vw;
padding-top:0.5vw;
padding-bottom:0.5vw;
margin-bottom:4vw;
color:var(--azul_itajai);
text-transform: uppercase;
font-weight:700;
font-size:4vw;
text-align: left;
letter-spacing:0vw;
background-color:var(--teste00);
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}
.HeaderMobile-sidenav-item svg{
fill:var(--amarelo_itajai);
margin-right:1vw;
margin-top:-1vw;
}
.HeaderMobile-sidenav-item-box{
background-color:var(--teste00);
}
.HeaderMobile-sidenav-item-li{
color:var(--cinza100);
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}
.HeaderMobile-sidenav-item-li-a,
.HeaderMobile-sidenav-item-li a{
background-color:var(--teste00);
height:auto;
font-weight:300;
font-size:4vw;
line-height:6vw;
color:var(--cinza33);
text-align: left;
letter-spacing:0vw;
margin-bottom:5vw;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}
.HeaderMobile-sidenav-item-nivel01{
background-color: var(--teste00) !important;
height: 13vw !important;
line-height: 13vw !important;
font-weight: 300 !important;
font-size: 6vw !important;
color: var(--cinza33) !important;
text-align: left !important;
letter-spacing: 0vw !important;
}
.HeaderMobile-subItemMenuNav{
padding:2vw;
}
.HeaderMobile-subItemMenuNav-titulo{
background-color:var(--teste00);
height:13vw;
line-height:13vw;
font-weight:300;
font-size:6vw;
color:var(--cinza33);
text-align: left;
letter-spacing:0vw;
}
.HeaderMobile-subItemMenuNav-li{
color:var(--cinza100);
}
.HeaderMobile-subItemMenuNav-li-a{
background-color:var(--teste00);
height:auto;
font-weight:300;
font-size:4vw;
line-height:6vw;
color:var(--cinza33);
text-align: left;
letter-spacing:0vw;
margin-bottom:5vw;
}
/*End - src/component/HeaderMobile/style.css*/