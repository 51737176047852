/*Start - src/components/ServicesAccordion/styles.css*/
.ServicesAccordion{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-top: 6.25vw;
}
.ServicesAccordion-container{
width: 93%;
display: flex;
flex-direction: column;
}
.ServicesAccordion-campo{
width: 100%;
height: 17.5vw;
display: flex;
flex-direction: row;
align-items: center;
background-color:var(--amarelo_itajai);
justify-content: space-between;
border-top-left-radius:1.8vw;
border-top-right-radius:1.8vw;
}
.ServicesAccordion-titulo{
font-family:var(--roboto);
font-style: normal;
font-weight: 700;
text-transform: uppercase;
padding: 3.5vw;
font-size: 4.3vw;
}
.ServicesAccordion-body{
background-color:var(--cinza249);
}
.ServicesAccordion-body li{
background-color:var(--teste00);
height:12vw;
line-height:5vw;
font-weight:500;
margin-bottom:2vw;
}
.ServicesAccordion-bottom{
width: 100%;
line-height:13vw;
height:13vw;
display: flex;
flex-direction: row;
align-items: center;
background-color:var(--amarelo_itajai);
justify-content: left;
font-size:4vw;
font-family:var(--roboto);
font-style: normal;
font-weight: 700;
text-align:left;
text-transform: uppercase;
border-bottom-left-radius:1.8vw;
border-bottom-right-radius:1.8vw;
padding-left:5vw;
}
.ServicesAccordion-bottom-link{
color:black; 
text-decoration: none;
}
.accordion-item{
border-radius:0vw !important;
border:0vw !important;
}
.accordion-header{
border-radius:0vw !important;
border:0vw;
}
.accordion-button {
background-color:var(--amarelo_itajai) !important;
font-weight: bold;
font-size: 2.9vw;
border-radius:0vw !important;
border:0vw;
text-transform: uppercase;
}
.accordion-button:focus {
box-shadow: none;
background-color:var(--amarelo_itajai02);
color:black;
}
.accordion-button:not(.collapsed) {
color:black !important;
background-color:var(--amarelo_itajai02) !important;
}
/*End - src/components/ServicesAccordion/styles.css*/