/*Start - src/pages/ListNews/style.css*/
.pages-ListNews{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.pages-ListNews-container{
width: 60%;
display: flex;
flex-direction: row;
margin-top: 0.8vw;
}
.pages-ListNews-container-coluna-esq{
width: 19%;
margin-right:1%;
display: flex;
flex-direction: column;
background-color:var(--teste00);
}
.page-ListNews-ItemCategorie{
font-family:var(--roboto);
font-style: normal;
font-size: 0.7vw;
line-height:0.8vw;
padding-top: 0.3vw;
padding-bottom: 0.3vw;
cursor:pointer;
text-transform: uppercase;
transition:all 0.1s ease-in-out;
color:var(--fonte);
font-weight:400;
}
.page-ListNews-ItemCategorie.ativo{
color:var(--azul_itajai02);
font-weight:600;
}
.page-ListNews-ItemCategorie:hover{
color:var(--azul_itajai02) !important;
text-decoration: underline;
transition:all 0.1s ease-in-out;
}
.pages-ListNews-empty{
width: 100%;
display: flex;
justify-content: center;
}
.pages-ListNews-container-coluna-dir{
width: 80%;
display: flex;
flex-direction: column;
align-items: flex-end;
background-color:var(--teste00);
}
.pages-ListNews-card-container{
width:100%;
display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 0.5vw;
}
.pages-ListNews-card-container a{
text-decoration:none;
}
.pages-ListNews-card-box{
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
}
.pages-ListNews-card-box img{
width:15.6vw; 
height:10.4vw;
border-radius:0.3vw; 
object-fit:contain;
}
.pages-ListNews-card-img{
width:100%; 
height:10.4vw;
border-radius:0.3vw; 
background-color: var(--azul_itajai02);
display: flex;
align-items: center;
justify-content: center;
}
.pages-ListNews-card-img svg{
color:white;
height:5vw;
}
.pages-ListNews-card-img.empty{
width: 15.6vw;
background-repeat:no-repeat;
background-position:center center;
background-size:auto 7vw;
}
.pages-ListNews-campo{
width: 100%;
display: flex;
flex-direction: column;
}
.pages-ListNews-titulo{
font-family:var(--roboto);
color:var(--cinza100);
font-style: normal;
font-weight: 700;
font-size: 0.9vw;
line-height:1.2vw;
margin-top: 0.5vw;
margin-bottom: 0.5vw;
}
.pages-ListNews-data{
font-family:var(--roboto);
color:var(--cinza100);
font-weight: 400;
font-style: normal;
font-size: 0.7vw;
display: flex;
flex: 3;
align-items: flex-end;
justify-content: flex-start;
text-transform: uppercase;
}
.pages-ListNews-container-coluna-dir.layoutGrid{
width: 90%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
}
.pages-ListNews-container-coluna-dir.layoutGrid a{
width: 100%;
text-decoration: none;
}
.pages-ListNews-layoutGrid-box{
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
}
.pages-ListNews-layoutGrid-box img{
width:15.6vw;
height: 9.4vw;
border-radius:0.3vw;
object-fit:cover;
}
.pages-ListNews-layoutGrid-box-img{
width: 15.6vw;
height: 9.4vw;
border-radius:0.3vw;
display: flex;
background-color: var(--cinza100);
align-items: center;
justify-content: center;
}
.pages-ListNews-layoutGrid-box-img svg{
color:white;
height:5vw;
}
.pages-ListNews-layoutGrid-campo{
width: 55%;
display: flex;
flex-direction: column;
margin-left: 0.5vw;
}
.pages-ListNews-layoutGrid-titulo{
font-family:var(--roboto);
color:var(--cinza33);
font-style: normal;
font-weight: 700;
font-size: 0.9vw;
margin-bottom: 0.5vw;
line-height:1vw;
}
.pages-ListNews-layoutGrid-resumo{
font-family:var(--roboto);
color:var(--cinza100);
font-style: normal;
font-weight: 400;
font-size: 0.8vw;
margin-bottom: 0.5vw;
line-height:1vw;
}
.pages-ListNews-layoutGrid-data{
display: flex;
flex: 3;
font-family:var(--roboto);
color:var(--cinza100);
font-weight: 400;
font-style: normal;
font-size: 0.7vw;
align-items: flex-end;
justify-content: flex-start;
text-transform: uppercase;
}
.pages-ListNews-layoutGrid-bar{
width: 100%;
border: 0.1vw solid var(--cinza205);
margin-top:1vw;
margin-bottom:1vw;
}
.pages-ListNews-paginacao{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color:var(--teste00);
margin-top:3vw;
margin-bottom:1vw;
}
.pages-ListNews-paginacao-container{
width: auto;
display: flex;
flex-direction: row;
background-color:var(--cinza222);
}
.pages-ListNews-btn{
margin:0.5vw;
background-color:var(--teste00);
padding:0.5vw;
display:block;
text-decoration: none;
color:var(--cinza100);
font-weight:500;
font-size:0.8vw;
line-height:1.3vw;
text-align: left;
letter-spacing:0vw;
cursor:pointer;
}
.pages-ListNews-btn.atual{
background-color:white;
}
.card {
margin-bottom: 0.5vw;
gap: 0.3vw;
}
.card-container-mobile {
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 1vw;
}
.pages-ListNews-filtro-btn{
display:none;
}
.pages-ListNews-filtro-campo{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.pages-ListNews-escurecer{
display:none;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.pages-ListNews-container{
width: 90vw;
margin-top:2.4vw;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}
.pages-ListNews-container-coluna-esq{
width: 80vw;
height: 100%;
position: fixed;
z-index: 3;
top: 0vw;
left: -90vw;
background-color:white;
gap: 4vw;
overflow-x: hidden;
transition:all 0.3s ease-in-out;
box-sizing: border-box;
-webkit-box-sizing: border-box;
padding-left: 4vw;
padding-right: 4vw;
padding-top: 4vw;
}
.pages-ListNews-container-coluna-esq.on{
left:0vw;
}
.pages-ListNews-container-coluna-dir{
width: 100%;
}
.pages-ListNews-card-container{
display: block;
}
.pages-ListNews-card-box{
margin-bottom:10vw;
}
.pages-ListNews-card-box img{
width:100%; 
height:60vw;
border-radius:1.5vw;
}
.pages-ListNews-card-img{
width:100%; 
height:60vw;
border-radius:1.5vw;
}
.pages-ListNews-card-img svg{
height:40vw;
}
.pages-ListNews-card-img.empty{
background-size:auto 40vw;
width:100%;
}
.pages-ListNews-campo{
margin-left: 0vw;
}
.pages-ListNews-titulo{
font-size:5vw;
line-height:6vw;
margin-top: 1.5vw;
margin-bottom: 1.5vw;
}
.pages-ListNews-data{
font-size:4vw;
}
.pages-ListNews-paginacao{
height:auto;
margin-top:9vw;
margin-bottom:3vw;
background-color:var(--cinza222);
}
.pages-ListNews-btn{
line-height:15vw;
margin:1.5vw;
padding:1.5vw;
width:10vw;
font-size:3vw;
text-align:center;
}
.card {
margin-bottom: 1.5vw;
gap:1vw;
}
.card-container-mobile {
gap: 3vw;
}
.pages-ListNews-container-coluna-dir.layoutGrid{
width:100%;
}
.pages-ListNews-container-coluna-dir.layoutGrid a {
width:100%;
}
.pages-ListNews-layoutGrid-box img{
width:45%;
height:30vw;
border-radius:1vw;
}
.pages-ListNews-layoutGrid-box-img{
width:45%;
height:30vw;
border-radius:1vw;
}
.pages-ListNews-layoutGrid-box-img svg{
height:15vw;
}
.pages-ListNews-layoutGrid-campo{
width: 55%;
margin-left: 1.5vw;
}
.pages-ListNews-layoutGrid-titulo{
font-size:4vw;
line-height:4vw;
margin-bottom: 1.5vw;
}
.pages-ListNews-layoutGrid-resumo{
font-size:4vw;
line-height:4vw;
margin-bottom: 1.5vw;
}
.pages-ListNews-layoutGrid-data{
font-size:3vw;
}
.pages-ListNews-layoutGrid-bar{
border: 0.3vw solid var(--cinza205);
margin-top:3vw;
margin-bottom:3vw;
}
.pages-ListNews-layoutGrid-box .empty{
width:45%;
height:30vw;
border-radius:1vw;
background-size: auto 20vw;
}
.pages-ListNews-layoutGrid-box img{
width:45%;
height:30vw;
border-radius:1vw;
}
.pages-ListNews-filtro-campo{
width:90vw;
display: block;
}
.pages-ListNews .TitlePage{
width:80vw;
float:left;
background-color:var(--teste00);
display:block;
}
.pages-ListNews-filtro-btn{
width: 10vw;
height: 8.1vw;
display: block;
float: left;
background-color: var(--teste00);
margin-top: 2.6vw;
text-align: right;
border-bottom: 0.7vw solid var(--cinza222);
}
.pages-ListNews-filtro-btn svg{
fill:var(--cinza100);
font-size:8vw;
}
.pages-ListNews-escurecer{
width: 100vw;
height:100vh;
position: fixed;
z-index: 2;
top: 0vw;
left: 0vw;
background-color:var(--preto_lente07);
display:none;
}
.pages-ListNews-escurecer.on{
display:block;
}
.page-ListNews-ItemCategorie{
font-size:4vw;
line-height:6vw;
padding-top: 1vw;
padding-bottom: 1vw;
}
.pages-ListNews-escurecer-fechar{
width:10vw;
height: 10vw;
position:absolute;
right:3vw;
top:3vw;
}
.pages-ListNews-escurecer-fechar svg{
height:10vw;
fill:var(--cinza100);
}

}
/*End - src/pages/ListNews/style.css*/