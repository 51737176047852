/*Start - src/components/Videos/style.css*/
.Videos{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-bottom: 4.9vw;
}
.Videos-container{
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-top:1.6vw;
}
.Videos-box{
display: flex;
flex-direction: column;
width:60%;
align-items: center;
justify-content: center;
}
.Videos-titulo{
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
font-family:var(--roboto);
font-style: normal;
font-size: 1.3vw;
font-weight: 700;
color:var(--cinza100);
text-transform: uppercase;
}
.Videos-bar{
width: 40%;
height:0.1vw;
display: flex;
align-items: center;
justify-content: center;
background-color:var(--fonte);
}
.Videos-itens{
width: 100%;
margin-top: 1.6vw;
background-color:var(--teste00);
display:flex;
flex-direction:row;
flex-wrap:wrap;
justify-content:space-between;
}
.Videos-item{
width:49%;
font-size:0.8vw;
background-color:var(--teste00);
}
.Videos-item a{
font-family:var(--roboto);
font-size: 1.1vw;
font-weight:700;
color:var(--cinza26);
text-decoration: none;
display:block;
}
.Videos-item img{
width:100%;
height:15.6vw;
margin-Bottom:0.8vw;
object-Fit:cover;
border-radius:0.5vw;
}
.Videos-item iframe{
width:100%;
height:15.6vw;
margin-Bottom:0.8vw;
object-Fit:cover;
border-radius:0.5vw;
}
.Videos-btn{
width:100%;
height:auto;
background-color:var(--azul_itajai02);
float:left;
margin-top:3vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
border-radius:0.3vw;
overflow:hidden;
position:relative;
}
.Videos-btn a{
font-weight:600;
font-size:0.8vw;
line-height:3vw;
color:white;
text-align: center;
letter-spacing:0vw;
text-decoration:none;
display:block;
text-transform: uppercase;
position:relative;
}
.Videos-btn-lente{
width:0vw;
height:0vh;
position:absolute;
background-color:var(--branco_lente02);
transition:all 0.3s ease-in-out;
top:3vw;
left: 50%;
border-radius:80vw;
transform: translate(-50%);
}
.Videos-btn:hover .Videos-btn-lente{
width:100vw;
height:100vh;
top:-3vw;
transition:all 0.3s ease-in-out;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.Videos{
margin-bottom:0vw;
padding-bottom:15vw;
}
.Videos-container{
margin-top:4.5vw;
}
.Videos-box{
width:90vw;
}
.Videos-itens{
margin-top:4.5vw;
}
.Videos-item{
width:100%;
font-size:4vw;
margin-bottom:8vw;
}
.Videos-item a{
font-size:4vw;
}
.Videos-item iframe{
height:48vw;
margin-Bottom:4vw;
border-radius:1.5vw;
}
.Videos-btn{
border-radius:1.5vw;
}
.Videos-btn a{
font-size:4vw;
line-height:15vw;
}

}
/*End - src/components/Videos/style.css*/