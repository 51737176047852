/*Start - src/components/ListImages/style.css*/
.ListImages{
width:100%;
background-color:rgba(255,0,0,0);
font-family:var(--roboto);
}
.ListImages-itens{
width:100%;
}
.ListImages-itens a{
margin-right:0.5vw;
}
.ListImages-itens a:nth-child(4n){
margin-right:0vw;
}
.ListImages-img{
width:14.4vw;
height:10.4vw;
border-radius:0.5vw; 
object-fit:cover;
margin-bottom:0.5vw;
cursor:pointer;
}
.ListImages-mobile-itens{
width:100%;
display:flex;
flex-direction:column;
align-items:center;
}
.ListImages-mobile-img{
width:15.6vw;
height:10.4vw;
border-radius:0.5vw; 
object-fit:cover;
}
.ListImages-lightbox-escurecer{
width:100%;
height:100%;
position:fixed;
z-index:9998;
background-color:var(--preto_lente07);
top:0vw;
left:0vw;
display:none;
}
.ListImages-lightbox{
width:90vw;
height:90vh;
position: fixed;
left:5vw;
top: 5vh;
z-index:9999;
background-color:var(--teste00);
border-radius:0.5vw;
overflow:hidden;
display:none;
}
.ListImages-lightbox-ativo{
display:block;
}
.ListImages_lightbox_conteudo{
width:100%;
height:100%;
background-repeat:no-repeat;
background-position:center center;
background-size:contain;
}
.ListImages-lightbox-fechar{
width:5vw;
height:5vw;
background-color:rgba(255,0,0,0);
float:right;
line-height:5vw;
text-align: center;
cursor:pointer;
}
.ListImages-lightbox-fechar svg{
height:2vw;
fill:white;
}
.ListImages-download{
width:100%;
height:auto;
background-color:var(--azul_itajai02);
float:left;
margin-top:0vw;
margin-bottom:2vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
border-radius:0.3vw;
font-weight:600;
font-size:0.8vw;
line-height:3vw;
color:white;
text-align: center;
letter-spacing:0vw;
text-decoration:none;
display:block;
text-transform: uppercase;
cursor:pointer;
}
.ListImages_full{
width:100%;
height:100%;
position:fixed;
z-index:9998;
background-color:var(--preto_lente07);
top:0vw;
left:0vw;
display:block;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.ListImages{}
.ListImages-itens{}
.ListImages-itens a{
margin-right:0vw;
}
.ListImages-itens a:nth-child(4n){
margin-right:0vw;
}
.ListImages-img{
width:100%;
height:20vw;
border-radius:1.6vw; 
margin-bottom:1.6vw;
}
.ListImages-mobile-itens{
width:100%;
}
.ListImages-mobile-itens a{
width:100%;
}
.ListImages-mobile-img{
width:100%;
height:60vw;
border-radius:2vw;
margin-bottom:5vw;
}
.ListImages-lightbox-fechar{
width:15vw;
height:15vw;
line-height:15vw;
}
.ListImages-lightbox-fechar svg{
height:6vw;
}
.ListImages-download{
height:15vw;
margin-bottom:6vw;
border-radius:1vw;
font-size:4vw;
line-height:15vw;
}

}
/*End - src/components/ListImages/style.css*/