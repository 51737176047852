/*Start - src/components/SubTitlePage/styles.css*/
.SubTitlePage{
width: 100%;
margin-top: 1vw;
}
.SubTitlePage-titulo{
color:var(--azul_itajai02);
font-size: 1vw;
font-weight: 300;
font-family:var(--roboto);
text-transform: uppercase;
}
.SubTitlePage-campo{
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-end;
margin-top: 0.5vw;
margin-bottom: 0.5vw;
}
.SubTitlePage-amarelo{
width:5%;
height: 0.2vw;
background-color:var(--amarelo_itajai);
}
.SubTitlePage-bar{
width: 95%;
height: 0.1vw;
background-color:var(--cinza222);
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.SubTitlePage{
margin-top: 3vw;
}
.SubTitlePage-titulo{
font-size: 4vw;
}
.SubTitlePage-campo{
margin-top: 1.5vw;
margin-bottom: 1.5vw;
}
.SubTitlePage-amarelo{
height: 0.6vw;
}
.SubTitlePage-bar{
height: 0.3vw;
}

}
/*End - src/components/SubTitlePage/styles.css*/