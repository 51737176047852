/*Start - src/assets/css/tema_escuro.css*/
body.tema_escuro{
color:white;
background-color:black;
}
body.tema_escuro .ToolBar,
body.tema_escuro .ToolBarMobile{
background-color:var(--cinza33);
}
body.tema_escuro .Header-btn,
body.tema_escuro .Header-btn a{
color:white;
}
body.tema_escuro .Header-itens a img,
body.tema_escuro .HeaderMobile-logo{
content:url(../../assets/imgs/logo-branca.png);
}
body.tema_escuro .HeaderMobile-hamburger{
color:white;
}
body.tema_escuro .HeaderMobile-sidenav{
background-color:black;
}
body.tema_escuro .HeaderMobile-sidenav-item-li,
body.tema_escuro .HeaderMobile-sidenav-item-li-a,
body.tema_escuro .HeaderMobile-subItemMenuNav-li,
body.tema_escuro .HeaderMobile-subItemMenuNav-li-a,
body.tema_escuro .HeaderMobile-subItemMenuNav-titulo,
body.tema_escuro .HeaderMobile-sidenav-closebtn,
body.tema_escuro .HeaderMobile-sidenav-inicio,
body.tema_escuro .HeaderMobile-sidenav-item{
color:white;
}
body.tema_escuro .hover-submenu-item{
background-color:var(--cinza33);
}
body.tema_escuro .hover-submenu-item-itemMenuNav{
color:white;
}
body.tema_escuro .SubItemMenuNav-titulo{
color:var(--amarelo_itajai);
}
body.tema_escuro .hover-submenu-item-itemMenuNav:hover{
color:var(--amarelo_itajai);
}
body.tema_escuro .SearchMobile-form,
body.tema_escuro input.SearchMobile-input,
body.tema_escuro .Search-form input{
background-color:var(--cinza33);
color:white;
}
body.tema_escuro .SearchMobile-form,
body.tema_escuro input.SearchMobile-input::placeholder,
body.tema_escuro .Search-form input::placeholder{
color:white;
}
body.tema_escuro .SearchMobile-ButtonMobile,
body.tema_escuro .Search-botoes button{
background-color:var(--cinza33);
color:white;
border-color:var(--cinza33);
}
body.tema_escuro .SearchMobile-lente{
background-color:var(--preto_lente05);
}
body.tema_escuro .MainNews-data{
color:white;
}
body.tema_escuro .MainNews-titulo{
color:white;
}
body.tema_escuro .SideTopNews-data{
color:white;
}
body.tema_escuro .SideTopNews-titulo{
color:white;
}
body.tema_escuro .Videos-titulo,
body.tema_escuro .AcessoRapido-titulo,
body.tema_escuro .MaisNoticias-titulo{
color:white;
}
body.tema_escuro .Videos-bar,
body.tema_escuro .AcessoRapido-titulo-bar,
body.tema_escuro .MaisNoticias-bar{
background-color:white;
}
body.tema_escuro .BottomNewsMobile,
body.tema_escuro .BottomNews{
background-color:var(--cinza33);
}
body.tema_escuro .BottomNewsMobile-titulo,
body.tema_escuro .BottomNews-titulo{
color:white;
}
body.tema_escuro .BottomNewsMobile-data,
body.tema_escuro .BottomNews-data{
color:white;
}
body.tema_escuro .ServicesAccordion-campo,
body.tema_escuro .Services-topo{
background-color:var(--cinza33);
}
body.tema_escuro .accordion-button{
background-color:var(--cinza33) !important;
color:white !important;
border-bottom-color:var(--cinza26);
}
body.tema_escuro .accordion-button::after{
color:white !important;
}
body.tema_escuro .accordion-button svg{
fill:white !important;
}
body.tema_escuro .ServicesAccordion-body{
background-color:var(--cinza100) !important;
color:white !important;
}
body.tema_escuro .ServicesAccordion-body li a{
color:white !important;
}
body.tema_escuro .ServicesAccordion-bottom{
background-color:var(--cinza33) !important;
}
body.tema_escuro .ServicesAccordion-bottom-link{
color:white !important;
}
body.tema_escuro .Services-btn-titulo{
color:white;
}
body.tema_escuro .Services-btn{
color:white;
}
body.tema_escuro .Services-conteudo{
background-color:black;
}
body.tema_escuro .Services-conteudo-item{
color:white;
}
body.tema_escuro .Services-bottom{
background-color:var(--cinza33);
}
body.tema_escuro .Services-bottom a{
color:white;
}
body.tema_escuro .Videos-item a{
color:white;
}
body.tema_escuro .Conectai{
background-color:var(--cinza26);
}
body.tema_escuro .Conectai-titulo{
color:white;
}
body.tema_escuro .MapSite{
background-color:var(--cinza33);
}
body.tema_escuro .Footer{
background-color:var(--cinza33);
}
body.tema_escuro .Header-pagina-interna{
border-bottom-color:var(--cinza33);
}
body.tema_escuro .Header-search-form-input{
background-color:var(--cinza33);
color:white;
}
body.tema_escuro .Header-search-form-input:placeholder{
color:white;
}
body.tema_escuro .TitlePage-titulo{
color:white;
}
body.tema_escuro .pages-mobile-Article-conteudo-box div,
body.tema_escuro .pages-Article-conteudo div{
background-color:black !important;
}
body.tema_escuro .pages-mobile-Entity-conteudo-box div,
body.tema_escuro .pages-Entity-conteudo div{
background-color:black !important;
}
body.tema_escuro .SubTitlePage-titulo{
color:white;
}
body.tema_escuro .pages-Entity-itemCategorie{
color:white;
}
body.tema_escuro .pages-Entity-itemCategorie.on{
color:var(--amarelo_itajai02);
}
body.tema_escuro .pages-Entity-itemCategorie:hover{
color:var(--amarelo_itajai02) !important;
}
body.tema_escuro .pages-Location-titulo{
color:var(--amarelo_itajai02);
}
body.tema_escuro .pages-Contact-destaque{
background-color:var(--cinza33);
}
body.tema_escuro .pages-Contact-destaque-telefone{
color:var(--amarelo_itajai02);
}
body.tema_escuro .pages-Contact-ListContact{
background-color:black;
}
body.tema_escuro .pages-Contact-Title{
background-color:var(--cinza26);
color:white;
}
body.tema_escuro .pages-Contact-Item{
background-color:var(--cinza33);
color:white;
}
body.tema_escuro .pages-Contact-Item:hover{
color:var(--amarelo_itajai02);
}
body.tema_escuro .TitlePage-iconNews-titulo{
color:white;
}
body.tema_escuro .page-ListNews-ItemCategorie{
color:white;
}
body.tema_escuro .page-ListNews-ItemCategorie.ativo{
color:var(--amarelo_itajai02);
}
body.tema_escuro .page-ListNews-ItemCategorie:hover{
color:var(--amarelo_itajai02) !important;
}
body.tema_escuro .TitlePage-iconNews-icone svg path{
stroke:white;
}
body.tema_escuro .pages-ListNews-titulo{
color:white;
}
body.tema_escuro .pages-ListNews-data{
color:white;
}
body.tema_escuro .pages-ListNews-layoutGrid-titulo{
color:white;
}
body.tema_escuro .pages-ListNews-layoutGrid-resumo{
color:white;
}
body.tema_escuro .pages-ListNews-layoutGrid-data{
color:white;
}
body.tema_escuro .pages-ListNews-paginacao-container{
background-color:var(--cinza33);
}
body.tema_escuro .pages-ListNews-paginacao{
background-color:black;
}
body.tema_escuro .pages-ListNews-btn{
color:white;
}
body.tema_escuro .pages-ListNews-btn.atual{
background-color:var(--cinza26);
}
body.tema_escuro .OtherNews-titulo{
color:white;
}
body.tema_escuro .OtherNews-data{
color:white;
}
body.tema_escuro .pages-News-data{
color:var(--cinza222);
}
body.tema_escuro .pages-News-subtitle{
color:var(--cinza222);
}
body.tema_escuro .pages-News-conteudo{
color:var(--cinza222);
}
body.tema_escuro .pages-News-conteudo p{
color:var(--cinza222);
}
body.tema_escuro .Videos-btn{
background-color:var(--cinza33);
}
body.tema_escuro .pages-ListVideos-item-titulo{
color:white;
}
body.tema_escuro .News-Mobile-CardMobileNews-titulo,
body.tema_escuro .News-Mobile-CardMobileNews-data{
color:white;
}
/*End - src/assets/css/tema_escuro.css*/