/*Start - src/components/Header/components/ItemMenuNav/style.css*/
.hover-submenu-container{
width:100%;
cursor:default;
}
.hover-submenu-container li{
list-style: none;
}
.hover-submenu-box{
box-shadow: 0 0.2vw 0.4vw 0 var(--preto_lente02), 0 0.3vw 1vw 0 var(--preto_lente02);
margin-top:1.9vw;
padding-top:0.5vw;
padding-bottom:0vw;
padding-left:0vw;
padding-right:0vw;
z-index: 6;
position:absolute;
background-color:var(--teste00);
box-shadow:none;
display:none;
}
.hover-submenu-item{
background-color: white;
border-radius:0.3vw;
padding:0.7vw;
box-shadow:0 0.2vw 0.4vw 0 var(--preto_lente01), 0 0.3vw 1vw 0 var(--preto_lente01);
}
.hover-submenu-item-itemMenuNav{
text-decoration: none;
color:var(--cinza155);
font-size: 0.7vw;
background-color:var(--teste00);
transition:all 0.1s ease-in-out;
}
.hover-submenu-item-itemMenuNav:hover{
color:var(--azul_itajai03);
transition:all 0.1s ease-in-out;
}
.hover-submenu-action:hover .hover-submenu-box{
display:block;
}
.hover-submenu-box:hover{
display:block;
}
.SubItemMenuNav{
padding:0.5vw;
background-color:var(--teste00);
}
.SubItemMenuNav-titulo{
margin-bottom:0.5vw;
background-color:var(--teste00);
}
.SubItemMenuNav ul{
background-color:var(--teste00);
}
.SubItemMenuNav ul li{
background-color:var(--teste00);
margin-bottom:0.2vw;
list-style: none;
}
/*End - src/components/Header/components/ItemMenuNav/style.css*/