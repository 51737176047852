body,
* {
margin: 0;
font-family:var(--roboto);
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

a,
div {
font-family:var(--roboto);
}