/*Start - src/components/Header/style.css*/
.Header{
display: flex;
align-Items: center;
justify-Content: center;
width: 100%;
background-color:var(--teste00);
}
.Header-pagina-interna{
border-bottom:0.1vw solid var(--branco235);
}
.Header-itens{
width: 60%;
display: flex;
justify-content: space-between;
padding-top: 1.5vw;
padding-bottom: 1.5vw;
background-color:var(--teste00);
}
.Header-itens img{
width:10.5vw;
}
.Header-button-box{
display:flex;
flex-direction:column;
justify-content:center;
align-items:flex-end;
background-color:var(--teste00);
}
.Header-search-form{
width: 50%;
height: 1.5vw;
display: flex;
border-radius: 0vw;
background-color:var(--teste00);
border-width: 0vw;
flex-direction: row;
margin-top: 0.5vw;
margin-bottom: 1.5vw;
}
.Header-search-form-input{
width: 100%;
background-color: var(--branco235);
border-width: 0vw;
color: var(--cinza120);
font-size: 0.7vw;
font-weight: 400;
font-family:var(--roboto);
border-top-left-radius:0.3vw;
border-bottom-left-radius:0.3vw;
border-top-right-radius:0vw;
border-bottom-right-radius:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
padding-left:0.5vw;
}
.Header-search-form-button{
width: 12%;
height: 100%;
font: inherit;
color: inherit;
display: flex;
border-top-left-radius:0vw;
border-bottom-left-radius:0vw;
border-top-right-radius:0.3vw;
border-bottom-right-radius:0.3vw;
background-color:var(--azul_itajai);
align-items: center;
justify-content: center;
border:0vw;
width:2.5vw;
cursor:pointer;
}
.Header-search-form-button svg{
height:1.3vw;
}
.Header-navBarHover{
display: flex;
align-items: flex-end;
text-transform: uppercase;
font-size: 0.8vw;
font-weight: 500;
font-family:var(--roboto);
color:var(--azul_itajai);
gap: 1vw;
background-color:var(--teste00);
}
.Header-btn{
height:2vw;
line-height:2vw;
float:left;
text-decoration: none;
color:var(--azul_itajai03);
background-color:var(--teste00);
white-space: nowrap;
}
.Header-btn a,
.Header-btn a:active,
.Header-btn a:visited,
.Header-btn a:focus{
line-height:2vw;
text-decoration:none;
color:var(--azul_itajai03);
display:block;
}
.Header-btn svg{
height:0.8vw;
vertical-align:middle;
margin-right:0.3vw;
margin-top:-0.1vw;
fill:var(--amarelo_itajai);
}
.Header-breadcrumb{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
margin-top: 1vw;
}
.Header-breadcrumb-item{
width:60%;
font-Weight: 300;
font-Size: 0.6vw;
text-Transform: uppercase;
}
.Header-breadcrumb-item a{
color:inherit;
}
.Header-breadcrumb-item a:hover{
text-decoration: underline;
}
/*End - src/components/Header/style.css*/