/*Start - src/pages/News/style.css*/
.pages-News{
width: 100%;
font-family:var(--roboto);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.pages-News-container{
width: 60%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-bottom: 2vw;
}
.pages-News-data{
align-self: flex-start;
color: var(--cinza120);
font-size: .8vw;
font-weight: 400;
letter-spacing: 0;
line-height: 1vw;
margin-bottom: 1.5vw;
margin-top: 0vw;
text-align: left;
}
.pages-News-img{
width: 30vw;
border-radius: 0.3vw;
margin-bottom: 1vw;
margin-top:0.7vw;
}
.pages-News-conteudo{
margin-top: 0.5vw;
margin-bottom: 1vw;
font-weight: 300;
font-size: 1vw;
color:var(--fonte);
text-align:justify;
}
.pages-News-conteudo p{
margin-top:0.5vw;
margin-bottom:0.5vw;
font-weight:400;
font-size:0.8vw;
line-height:1.3vw;
color:var(--fonte);
text-align: justify;
letter-spacing:0vw;
}
.pages-News-conteudo a{
color:var(--fonte);
}
.pages-News-subtitle{
color: var(--fonte);
font-size: 1vw;
font-style: italic;
font-weight: 500;
margin-top: 0.5vw;
margin-bottom:1vw;
text-align: left;
align-self: flex-start;
}
.pages-News-fotografo{
background-color:var(--branco_lente07);
font-weight:400;
font-size:0.7vw;
line-height:1vw;
color:var(--fonte);
text-align: left;
letter-spacing:0vw;
position:absolute;
margin-top:-2vw;
padding-left:1vw;
padding-right:1vw;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.pages-News-container{
width:90vw;
padding-bottom:6vw;
}
.pages-News-data{
font-size:4vw;
line-height:6vw;
margin-top:3vw;
margin-bottom:3vw;
}
.pages-News-img{
border-radius:1vw;
margin-bottom: 1.5vw;
width:100%;
}
.pages-News-conteudo{
margin-top: 1.5vw;
margin-bottom:3vw;
font-size: 4vw;
line-height:6vw;
}
.pages-News-conteudo p{
margin-top:1.5vw;
margin-bottom:1.5vw;
font-size:4vw;
font-weight:300;
line-height:8vw;
color:var(--cinza100);
}
.pages-News-mobile-separador{
height:5vw;
}
.pages-News-subtitle{
margin-top: 1.5vw;
font-size: 4vw;
}
.pages-News-fotografo{
font-size:4vw;
line-height:6vw;
margin-top:-7vw;
padding-left:3vw;
padding-right:3vw;
}

}
/*End - src/pages/News/style.css*/