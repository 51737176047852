/*Start - src/pages/NotFound/style.css*/
.pages-NotFound{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-family:var(--roboto);
}
.pages-NotFound-container{
width: 60%;
margin-top: 0.8vw;
}
.pages-NotFound-esq{
width: 30%;
display: flex;
flex-direction: column;
padding-bottom:2vw;
}
.pages-NotFound-esq-titulo{
font-family:var(--roboto);
color:var(--azul_itajai02);
font-style: normal;
font-weight: 600;
font-size: 0.8vw;
margin-bottom: 0.5vw;
text-transform: uppercase;
}
.pages-NotFound-itemCategorie{
font-family:var(--roboto);
font-style: normal;
font-size: 0.7vw;
line-height:0.8vw;
padding-top: 0.3vw;
padding-bottom: 0.3vw;
cursor:pointer;
text-transform: uppercase;
transition:all 0.1s ease-in-out;
color:var(--fonte);
font-weight:400;
}
.pages-NotFound-itemCategorie:hover{
color:var(--azul_itajai02) !important;
text-decoration: underline;
transition:all 0.1s ease-in-out;
}
.pages-NotFound-dir{
width: 70%;
display: flex;
flex-direction: column;
margin-bottom: 2.1vw;
}
.pages-NotFound-bloco-master{
width: 100%;
display: flex;
flex-direction: column;
}
.pages-NotFound-bloco{
width: 100%;
display: flex;
flex-direction: column;
margin-bottom: 2.1vw;
}
.pages-NotFound-empty{
font-family:var(--roboto);
color:var(--cinza100);
font-style: normal;
font-weight: 600;
font-size: 0.8vw;
margin-bottom: 0.5vw;
align-self: center;
margin-bottom: 2.1vw;
}
.pages-NotFound-item{
font-family:var(--roboto);
color:var(--cinza100);
font-style: normal;
font-weight: 400;
font-size: 0.8vw;
margin-bottom: 0.5vw;
align-self: left;
text-decoration:none;
margin-top: 0.5vw;
transition:all 0.1s ease-in-out;
}
.pages-NotFound-item:hover{
color:var(--azul_itajai02);
transition:all 0.1s ease-in-out;
}
.pages-mobile-NotFound{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.pages-mobile-NotFound a,
.pages-mobile-NotFound a:active,
.pages-mobile-NotFound a:visited,
.pages-mobile-NotFound a:focus{
text-decoration: none;
color:inherit;
font-weight: inherit;
}
.pages-mobile-NotFound-container{
width:90vw;
display: flex;
flex-direction: column;
margin-top:3vw;
}
.pages-mobile-NotFound-container ul{
margin-top:5vw;
}
.pages-mobile-NotFound-item{
margin-bottom: 2vw;
list-style:none;
}
.pages-mobile-NotFound-item a{
font-family:var(--roboto);
font-style: normal;
font-size:4vw;
line-height:6vw;
padding-top:1vw;
padding-bottom:1vw;
cursor:pointer;
color:var(--fonte);
text-decoration: none;
display:block;
background-color:var(--teste00);
}
.pages-mobile-NotFound-separador{
height:10vw;
}
.pages-NotFound-filtro-descricao{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
cursor: pointer;
font-family:var(--roboto);
font-size:1vw;
font-style: normal;
line-height:1vw;
padding-top: 0.3vw;
padding-bottom: 0.3vw;
text-transform: uppercase;
transition: all 0.1s ease-in-out;
color: var(--azul_itajai02);
font-weight: 600;
}
.pages-NotFound-filtro-counter{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
color: var(--cinza155);
cursor: pointer;
font-family:var(--roboto);
font-size: 0.7vw;
font-style: normal;
font-weight: 400;
line-height: 0.8vw;
padding-top: 0.3vw;
padding-bottom: 1vw;
text-transform: uppercase;
transition: all 0.1s ease-in-out;
}
.pages-NotFound-filtro-titulo{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
color: var(--fonte);
cursor: pointer;
font-family:var(--roboto);
font-size: 0.7vw;
font-style: normal;
line-height: 0.8vw;
padding-top: 0.3vw;
padding-bottom: 0.8vw;
text-transform: uppercase;
transition: all 0.1s ease-in-out;
font-weight: 600;
}
.pages-NotFound-filtro-item{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
color: var(--cinza120);
cursor: pointer;
font-family:var(--roboto);
font-size: 0.7vw;
font-style: normal;
font-weight: 400;
line-height: 0.8vw;
padding-bottom: 0.3vw;
padding-top: 0.3vw;
text-transform: uppercase;
transition: all 0.1s ease-in-out;
}
.pages-NotFound-filtro-item:hover{
text-decoration: underline;
color: var(--azul_itajai02);
transition:all 0.2s ease-in-out;
}
.pages-NotFound-filtro-claro{
color: var(--cinza205);
}
.pages-NotFound-filtro-btn-campo{
width:80%;
height:auto;
background-color:var(--cinza245);
float:left;
margin-top:1vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
padding:0.5vw;
border-radius:0.3vw;
border: 0.1vw solid var(--cinza222);
cursor:pointer;
}
.pages-NotFound-filtro-btn{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
color: var(--fonte);
cursor: pointer;
font-family:var(--roboto);
font-size: 0.7vw;
font-style: normal;
line-height: 0.8vw;
padding-top: 0.3vw;
padding-bottom: 0.5vw;
text-transform: uppercase;
transition: all 0.1s ease-in-out;
font-weight: 600;
color: var(--azul_itajai02);
}
.pages-NotFound-filtro-aviso{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
color: var(--cinza120);
cursor: pointer;
font-family:var(--roboto);
font-size: 0.7vw;
font-style: normal;
font-weight: 400;
line-height: 0.8vw;
padding-bottom: 0.3vw;
padding-top: 0.3vw;
text-transform: uppercase;
transition: all 0.1s ease-in-out;
font-style: italic;
}
.pages-NotFound-filtro-btn-campo:hover .pages-NotFound-filtro-btn{
text-decoration: underline;
transition:all 0.2s ease-in-out;
}
.pages-NotFound-filtro-btn-campo:hover{
background-color:white;
}
.pages-NotFound-filtro-reset{
width:50%;
height:auto;
background-color:var(--cinza245);
float:left;
margin-top:0vw;
margin-bottom:1vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
color: var(--cinza120);
cursor: pointer;
font-family:var(--roboto);
font-size: 0.7vw;
font-style: normal;
font-weight: 400;
line-height: 0.8vw;
padding-bottom: 0.3vw;
padding-top: 0.3vw;
text-transform: uppercase;
transition: all 0.1s ease-in-out;
padding:0.5vw;
cursor:pointer;
border: 0.1vw solid var(--cinza222);
border-radius:0.3vw;
}
.pages-NotFound-filtro-reset:hover{
background-color:white;
}
.pages-NotFound-filtro-reset-x{
width:1vw;
height:0.7vw;
background-color:var(--teste00);
float:right;
line-height:0.7vw;
text-align: center;
}
.pages-NotFound-filtro-reset-x svg{
height:0.7vw;
fill:var(--cinza120);
}
.pages-NotFound-separador{
width: 60%;
height:2vw;
display: flex;
background-color:var(--teste00);
}
/*End - src/pages/NotFound/style.css*/