/*Start - src/pages/Article/style.css*/
.pages-Article{
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
justify-content: center;
}
.pages-Article-container{
display: flex;
flex-direction: row;
width: 60%;
margin-top: 0.8vw;
padding-bottom:5vw;
}
.pages-Article-col-esq{
display: flex;
flex-direction: column;
width: 30%;
}
.pages-Article-col-esq-topo{
font-family:var(--roboto);
color:var(--fonte);
font-style: normal;
font-weight: 600;
font-size: 0.8vw;
margin-bottom: 0.5vw;
text-transform: uppercase;
}
.pages-Article-categoria-item{
font-family:var(--roboto);
color:var(--fonte);
font-style: normal;
font-weight:400;
font-size: 0.8vw;
margin-bottom: 0.5vw;
cursor: pointer;
text-decoration: none;
}
.pages-Article-categoria-item.ativo{
color:var(--azul_itajai02);
font-weight:600;
}
.pages-Article-conteudo{
width:60%;
}
.pages-Article-conteudo.full{
width:100%;
}
.pages-Article-conteudo-vazio{
display: flex;
flex-direction: column;
width: 60%;
align-items: center;
}
.pages-Article-vazio.full{
width:100%;
}
.pages-Article-ItemCategorie-generico{
font-family:var(--roboto);
font-style: normal;
font-size: 0.8vw;
margin-bottom: 0.5vw;
cursor: pointer;
text-decoration: none;
}
.pages-Article-ItemCategorie{
font-family:var(--roboto);
font-style: normal;
font-size: 0.8vw;
margin-bottom: 0.3vw;
cursor:pointer;
text-transform: uppercase;
text-decoration: none;
transition:all 0.1s ease-in-out;
}
.pages-Article-ItemCategorie:hover{
color:var(--azul_itajai02) !important;
text-decoration: underline;
transition:all 0.1s ease-in-out;
}
.pages-mobile-Article{
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
justify-content: center;
}
.pages-mobile-Article-conteudo{
display: flex;
flex-direction: row;
width:93%;
margin-top: 0.8vw;
}
.pages-mobile-Article-separador{
height:8.2vw;
}
.pages-mobile-Article-conteudo-box{
width:90vw;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.pages-Article-container{
padding-bottom:15vw;
}
.pages-mobile-Article-conteudo img{
width:100% !important;
height:auto !important;
margin-bottom:3vw;
}

}
/*End - src/pages/Article/style.css*/