/*Start - src/pages/Entity/style.css*/
.pages-Entity{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-family:var(--roboto);
}
.pages-Entity-container{
width: 60%;
display: flex;
flex-direction: row;
justify-content: space-between;
margin-top: 0.8vw;
margin-bottom: 1.6vw;
}
.pages-Entity-esq{
width: 30%;
display: flex;
flex-direction: column;
}
.pages-Entity-item{
font-family:var(--roboto);
color:var(--azul_itajai02);
font-style: normal;
font-weight: 600;
font-size: 0.8vw;
margin-bottom: 0.5vw;
text-transform: uppercase;
}
.pages-Entity-dir{
width: 80%;
display: flex;
flex-direction: column;
}
.pages-Entity-conteudo{
width: 100%;
}
.pages-Entity-conteudo-txt{
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
font-size: 1vw;
font-weight: 300;
}
.pages-Entity-email{
width: 35%;
display: flex;
flex-direction: column;
}
.pages-Entity-telefone{
width: 35%;
display: flex;
flex-direction: column;
}
.pages-Entity-endereco-container{
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
font-size: 1vw;
font-weight: 300;
margin-top: 1.6vw;
margin-bottom: 1.6vw;
}
.pages-Entity-endereco{
width: 35%;
display: flex;
flex-direction: column;
}
.pages-Entity-itemCategorie-generico{
font-family:var(--roboto);
font-style: normal;
font-size: 0.8vw;
margin-bottom: 0.5vw;
cursor: pointer;
text-transform: uppercase;
text-decoration: none;
color:var(--cinza100);
font-weight:400;
}
.pages-Entity-itemCategorie-generico.on{
color:var(--azul_itajai02);
font-weight:600;
}
.pages-Entity-itemCategorie{
font-family:var(--roboto);
font-style: normal;
font-size: 0.7vw;
margin-bottom: 0.3vw;
cursor:pointer;
text-transform: uppercase;
text-decoration: none;
transition:all 0.1s ease-in-out;
color:var(--cinza100);
font-weight:400;
}
.pages-Entity-itemCategorie.on{
color:var(--azul_itajai02);
font-weight:600;
}
.pages-Entity-itemCategorie:hover{
color:var(--azul_itajai02) !important;
text-decoration: underline;
transition:all 0.1s ease-in-out;
}
.pages-Entity-separador{
width: 60%;
height:2vw;
display: flex;
background-color:var(--teste00);
}
.pages-Entity-container-full{
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
font-size: 1vw;
font-weight: 300;
margin-top: 0.5vw;
margin-bottom: 0.5vw;
background-color:var(--teste00);
}
.pages-Entity-linha{
width: 100%;
display: flex;
flex-direction: column;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.pages-Entity-container{
width:90vw;
margin-top:4vw;
margin-bottom:8vw;
}
.pages-Entity-esq{
display:none;
}
.pages-Entity-item{
font-size:4vw;
margin-bottom: 1.5vw;
}
.pages-Entity-dir{
width:100%;
}
.pages-Entity-conteudo{
width:100%;
font-size:4vw;
font-weight:300;
}
.pages-Entity-conteudo-txt{
font-size:4vw;
}
.pages-Entity-email{
width: 100%;
}
.pages-Entity-telefone{
width: 100%;
}
.pages-Entity-endereco-container{
width: 100%;
font-size: 4vw;
margin-top:3.8vw;
margin-bottom:3.8vw;
}
.pages-Entity-endereco{
width:100%;
}
.pages-Entity-itemCategorie-generico{
font-size:4vw;
margin-bottom: 1.5vw;
}
.pages-Entity-itemCategorie{
font-size:4vw;
margin-bottom:1vw;
}
.pages-Entity-separador{
width: 100%;
height:6vw;
}
.pages-Entity-mobile-separador{
height:5vw;
}
.pages-Entity-mobile100{
display:block;
}
.pages-Entity-container-full{
font-size:4vw;
margin-top:6vw;
margin-bottom:6vw;
}

}
/*End - src/pages/Entity/style.css*/