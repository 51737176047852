/*Start - src/assets/css/global.css*/
body{
margin:0;
padding:0;
font-family:var(--roboto);
font-size: 0.8vw;
line-height: 1.3vw;
color:var(--fonte);
background-color:transparent;
overflow-x: hidden;
}
a,
a:active,
a:visited,
a:focus{
text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
transition: background-color 0s 600000s, color 0s 600000s;
}
svg{
height:1vw;
fill:black;
}

/*Start - Scrollbar*/
*{
scrollbar-color: var(--azul_itajai) white;
}
::-webkit-scrollbar{
background-color:white;
}
::-webkit-scrollbar-thumb{
background-color:var(--azul_itajai);
border-radius:50px;
}
/*End - Scrollbar*/

@media only screen and (max-width:1024px) and (orientation: portrait){

body{
font-size: 4vw;
line-height: 6vw;
}
svg{
height:4vw;
}

}

/*End - src/assets/css/global.css*/