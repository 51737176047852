/*Start - src/components/ToolBar/style.css*/
.ToolBar{
display:flex;
width:100%;
height:2.1vw;
align-items:center;
justify-content:center;
background-color:var(--azul_itajai02);
}
.ToolBar-container{
display: flex;
flex-direction: row;
width: 60%;
height: 100%;
align-items: center;
justify-content: space-between;
background-color:var(--teste00);
}
.ToolBar-botoes{
width: 45%;
height:100%;
display: flex;
color:white;
text-transform: uppercase;
font-size: 0.7vw;
font-weight: 500;
justify-content: space-between;
box-sizing: border-box;
-webkit-box-sizing: border-box;
}
.ToolBar-botoes a{
text-decoration:none;
color:white;
cursor:pointer;
line-height:2.1vw;
background-color:var(--teste00);
padding-left:0.3vw;
padding-right:0.3vw;
}
.ToolBar-acessibilidade-botoes{
width: 20%;
height:100%;
display: flex;
color:white;
text-transform: uppercase;
align-items: center;
font-size: 0.7vw;
font-weight: bold;
gap:0.8vw;
background-color:var(--teste00);
}
.ToolBar-acessibilidade-btn{
width:2.1vw;
height:100%;
line-height:2.1vw;
cursor:pointer;
font-size: 0.7vw;
font-weight: bold;
text-align:center;
background-color:var(--teste00);
}
.ToolBar-acessibilidade-btn svg{
fill:white;
height:0.8vw;
}
.ToolBar-contato-container{
width: 27%;
height:100%;
display: flex;
padding: 0.5vw;
flex-direction: row;
align-items: center;
justify-content: space-between;
color:white;
text-transform: uppercase;
font-size: 0.9vw;
font-weight: bold;
background-color:var(--teste00);
}
.ToolBar-contato-btn{
height:2.1vw;
background-color:var(--teste00);
}
.ToolBar-contato-btn a{
height:2.1vw;
line-height:2vw;
display:block;
text-decoration:none;
color:white;
background-color:var(--teste00);
}
.ToolBar-contato-btn.telefone{
display: flex;
flex-direction: row;
align-items: center;
}
.ToolBar-contato-btn.telefone span{
margin-left: 0.5vw;
}
.ToolBar-contato-btn svg{
width:1vw;
height:1vw;
fill:white;
}
.ToolBarMobile{
display:none;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.ToolBarMobile{
display: flex;
box-sizing: border-box;
-webkit-box-sizing: border-box;
width: 100%;
height:15vw;
align-items: center;
justify-content: center;
background-color:var(--azul_itajai02);
}
.ToolBarMobile-container{
display: flex;
width: 90vw;
align-items: center;
justify-content: space-between;
}
.ToolBar-contato-btn{
height:15vw;
}
.ToolBar-contato-btn a{
height:15vw;
line-height:15vw;
}
.ToolBar-contato-btn.telefone{
}
.ToolBar-contato-btn.telefone span{
margin-left: 1.5vw;
}
.ToolBar-contato-btn svg{
width:4vw;
height:4vw;
}
.ToolBar-botoes{
padding: 2.1vw;
font-size: 4vw;
}
.ToolBar-acessibilidade-botoes{
padding: 2.1vw;
font-size: 4vw;
gap: 4.8vw;
}
.ToolBar-acessibilidade-btn{
width:6.3vw;
font-size: 4vw;
}
.ToolBar-acessibilidade-btn svg{
height:4vw
}

}
/*End - src/components/ToolBar/style.css*/