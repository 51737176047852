/*Start - src/components/News/style.css*/
.News{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin-top:1vw;
}
.SubContainer{
width:60%;
}
.MainNews{
text-decoration: none;
width: calc(50% - 1vw);
float: left;
background-color:var(--teste00);
margin-right:1vw;
}
.MainNews-img{
width:100%;
height:15.6vw;
border-radius:0.3vw;
object-fit:cover;
}
.MainNews-data{
color:var(--cinza155);
font-weight: 400;
font-style: normal;
font-size: 0.8vw;
margin-top: 0.8vw;
transition:all 0.2s ease-in-out;
}
.MainNews-titulo{
color:var(--cinza100);
font-style: normal;
font-weight: 700;
font-size: 1.2vw;
line-height: 1.3vw;
transition:all 0.2s ease-in-out;
}
.MainNews:hover .MainNews-titulo{
color:var(--azul_itajai02);
transition:all 0.2s ease-in-out;
}
.MainNews:hover .MainNews-data{
color:var(--azul_itajai02);
transition:all 0.2s ease-in-out;
}
.ListSideNews{
width:50%;
display:flex;
flex-direction:column;
}
.SideTopNews{
width: 100%;
text-decoration: none;
float:left;
background-color:var(--teste00);
margin-bottom: 1vw;
}
.SideTopNews-noImg{
height:9.4vw;
}
.SideTopNews-img{
width:52%;
height:9.4vw;
border-Radius:0.3vw;
float:left;
}
.SideTopNews-container{
width:46%;
float:left;
background-color:var(--teste00);
margin-left: 2%;
}
.SideTopNews-box{
width:100%;
}
.SideTopNews-titulo{
color:var(--cinza100);
font-style: normal;
font-weight: 700;
font-size: 0.9vw;
line-height: 0.9vw;
transition:all 0.2s ease-in-out;
}
.SideTopNews-data{
color:var(--cinza155);
font-Weight: 400;
font-Style: normal;
font-Size: 0.7vw;
line-height:1.4vw;
transition:all 0.2s ease-in-out;
}
.SideTopNews-resumo{
color:var(--cinza100);
font-Weight: 400;
font-Style: normal;
font-Size: 0.7vw;
line-height: 0.8vw;
transition:all 0.2s ease-in-out;
}
.SideTopNews:hover .SideTopNews-titulo{
color:var(--azul_itajai02);
transition:all 0.2s ease-in-out;
}
.SideTopNews:hover .SideTopNews-data{
color:var(--azul_itajai02);
transition:all 0.2s ease-in-out;
}
.SideTopNews:hover .SideTopNews-resumo{
color:var(--azul_itajai02);
transition:all 0.2s ease-in-out;
}
.MaisNoticias{
width:100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color:var(--teste00);
margin-top:1.5vw;
}
.MaisNoticias-container{
width:60%;
background-color:var(--teste00);
}
.MaisNoticias-titulo{
width: 20%;
float:left;
line-height:2vw;
background-color:var(--teste00);
font-family:var(--roboto);
font-style: normal;
font-size: 1.3vw;
font-weight: 700;
color:var(--cinza100);
text-transform: uppercase;
text-align:center;
}
.MaisNoticias-bar{
width: 40%;
margin-top:1vw;
height:0.1vw;
background-color:var(--fonte);
float:left;
}
.ListBottomNews{
width: 60%;
height:auto;
margin-top:2vw;
margin-bottom: 1.6vw;
background-color:var(--teste00);
display: flex;
flex-wrap:wrap;
justify-content:start;
}
.multi-line-ellipsis {
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}
.multi-line-ellipsis-mobile {
overflow: hidden;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.BottomNews{
width:14.4vw;
height:7vw;
margin-right:0.5vw;
margin-bottom:0.5vw;
background-color:var(--cinza245);
border-radius:0.3vw;
padding:0.5vw;
text-decoration: none;
overflow:hidden;
float:left;
box-sizing: border-box;
-webkit-box-sizing: border-box;
position:relative;
transition:all 0.3s ease-in-out;
}
.BottomNews-lente{
width:15vw;
height:30vw;
position:absolute;
top:0vw;
left:0vw;
background-color:var(--azul_itajai02);
-moz-transform: rotate(90deg);
-o-transform: rotate(90deg);
-webkit-transform: rotate(90deg);
transform: rotate(90deg);
transition:all 0.3s ease-in-out;
}
.BottomNews:hover .BottomNews-lente{
-moz-transform: rotate(0deg);
-o-transform: rotate(0deg);
-webkit-transform: rotate(0deg);
transform: rotate(0deg);
transition:all 0.3s ease-in-out;
}
.BottomNews:nth-child(4) {
margin-right:0vw;
}
.BottomNews:nth-child(8) {
margin-right:0vw;
}
.BottomNews-img{
width:5vw;
height:5vw;
float:left;
margin-top:0.5vw;
margin-right:0.4vw;
background-repeat:no-repeat;
background-position:center center;
background-size:cover;
border-radius:0.3vw;
position:relative;
}
.BottomNews-col{
width:8vw;
height:100%;
float:left;
display: table;
position: relative;
background-color:var(--teste00);
}
.BottomNews-col span{
width:100%;
height:100%;
display:block;
display: table-cell;
vertical-align: middle;
}
.BottomNews-noImg{
width:100%;
}
.BottomNews-titulo-box{
width:100%;
height:auto;
}
.BottomNews-titulo{
width: 100%;
color:var(--cinza100);
font-Style: normal;
font-Weight: 600;
font-Size: 0.9vw;
line-height:1vw;
text-Overflow: ellipsis;
overflow: hidden;
transition:all 0.3s ease-in-out;
}
.BottomNews:hover .BottomNews-titulo{
color:white;
transition:all 0.3s ease-in-out;
}
.BottomNews-data{
color:var(--cinza100);
font-weight: 400;
font-style: normal;
font-size: 0.7vw;
transition:all 0.3s ease-in-out;
}
.BottomNews:hover .BottomNews-data{
color:white;
transition:all 0.3s ease-in-out;
}
.News-Mobile{
display: flex;
flex-direction: column;
width: 93%;
align-self: center;
margin-top: 4.1vw;
}
.News-Mobile-linha01{
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-between;
gap: 2vw;
}
.News-Mobile-linha02{
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-between;
margin-top: 2vw;
gap: 2vw;
margin-bottom: 4vw;
}
.News-Mobile-CardMobileNews{
width: 50%;
display: flex;
flex-direction: column;
text-decoration: none;
}
.News-Mobile-CardMobileNews-img{
width:100%;
height:28vw;
border-radius:1.8vw;
object-fit: cover;
margin-bottom:2vw;
}
.News-Mobile-CardMobileNews-campo{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
}
.News-Mobile-CardMobileNews-titulo{
font-family:var(--roboto);
color:var(--cinza100);
font-style: normal;
font-weight: 700;
font-size:3.8vw;
line-height:4.2vw;
}
.News-Mobile-CardMobileNews-data{
font-family:var(--roboto);
color:var(--cinza100);
font-weight: 400;
font-style: normal;
font-size: 2.5vw;
margin-top: 2vw;
margin-bottom: 2vw;
}
.BottomNewsMobile{
width:100%;
height:auto;
display: flex;
flex-direction: column;
margin-bottom:3vw;
background-color:var(--cinza245);
border-radius:1.8vw;
padding-top: 3.5vw;
padding-bottom: 4.1vw;
padding-left:2vw;
padding-right:2vw;
justify-content: space-between;
align-items: flex-start;
text-decoration: none;
}
.BottomNewsMobile-campo{
width: 100%;
height:auto;
}
.BottomNewsMobile-titulo{
width: 100%;
color:var(--cinza100);
font-style: normal;
font-weight: 700;
font-size: 3.7vw;
}
.BottomNewsMobile-data{
color:var(--cinza100);
font-weight: 400;
font-style: normal;
font-size: 2.9vw;
}
.News-btn{
width:60%;
height:auto;
background-color:var(--azul_itajai02);
float:left;
margin-top:0vw;
margin-bottom:2vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
border-radius:0.3vw;
overflow:hidden;
position:relative;
}
.News-btn a{
font-weight:600;
font-size:0.8vw;
line-height:3vw;
color:white;
text-align: center;
letter-spacing:0vw;
text-decoration:none;
display:block;
text-transform: uppercase;
position:relative;
}
.News-btn-lente{
width:0vw;
height:0vh;
position:absolute;
background-color:var(--branco_lente02);
transition:all 0.3s ease-in-out;
top:3vw;
left: 50%;
border-radius:80vw;
transform: translate(-50%);
}
.News-btn:hover .News-btn-lente{
width:100vw;
height:100vh;
top:-3vw;
transition:all 0.3s ease-in-out;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.News{
margin-top:0.5vw;
}
.BottomNews{
width:100%;
height:24vw;
margin-bottom:3vw;
border-radius:1.5vw;
padding-top:3vw;
padding-bottom:3vw;
padding-left:3vw;
padding-right:3vw;
}
.News-btn{
width:100%;
height:15vw;
border-radius:1.5vw;
}
.News-btn a{
font-size:4vw;
line-height:15vw;
}

}
/*End - src/components/News/style.css*/