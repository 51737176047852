/*start - src/components/Conectai/style.css*/
@keyframes shake{
0%{transform:rotate(0deg);}
33%{transform:rotate(-5deg);}
66%{transform:rotate(5deg);}
100%{transform:rotate(0deg);}
}
.Conectai{
width: 100%;
height: 36.1vw;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
background-color:var(--cinza205);
background-image: linear-gradient(to bottom, white, var(--cinza155));
padding-top:2vw;
}
.Conectai-container{
width:60%;
height:100%;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 2.6vw;
}
.Conectai-telefone{
width:18vw;
height:27vw;
object-fit: contain;
}
.Conectai-col{
width: 40%;
display: flex;
flex-direction: column;
}
.Conectai-btn{
width: 25vw;
line-height:3vw;
padding: 0.7vw;
border-radius: 0.5vw;
background-color: var(--azulConectaiFundo);
font-family:var(--montserrat);
font-style: normal;
font-size: 1vw;
font-weight: 600;
color:var(--azulConectaiFonte);
margin-bottom: 1.6vw;
text-align: center;
text-decoration: none;
text-transform: uppercase;
}
.Conectai-titulo{
font-family:var(--montserrat);
font-style: normal;
font-size: 2.1vw;
line-height:2.1vw;
font-weight: 900;
color:var(--cinza26);
margin-top:1vw;
margin-bottom:1vw;
}
.Conectai-app-btn{
width:25vw;
margin-bottom: 1.6vw;
}
.Conectai-app-btn.apple{
margin-top: 1.6vw;
}
.Conectai:hover .Conectai-telefone{
animation:shake 0.3s normal;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.Conectai{
height: auto;
padding-top:6vw;
padding-bottom:6vw;
}
.Conectai-container{
width:90vw;
gap:2.5vw;
}
.Conectai-telefone{
width:40%;
height:80vw;
object-fit: contain;
}
.Conectai-col{
width: 60%;
}
.Conectai-btn{
width: 100%;
line-height:9vw;
padding: 2.1vw;
border-radius: 1.5vw;
font-size: 3vw;
margin-bottom:4.8vw;
}
.Conectai-titulo{
font-size:6.3vw;
line-height:6.3vw;
margin-top:3vw;
margin-bottom:3vw;
}
.Conectai-app-btn{
width:100%;
margin-bottom:4.8vw;
}
.Conectai-app-btn.apple{
margin-top:4.8vw;
}

}
/*end - src/components/Conectai/style.css*/