/*Start - src/pages/Contact/style.css*/
.pages-Contact{
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
justify-content: center;
font-family:var(--roboto);
}
.pages-Contact-container{
display: flex;
flex-direction: column;
align-items: center;
width: 60%;
padding-bottom: 4.2vw;
}
.pages-Contact-destaque{
background-color:var(--cinza245);
width:auto;
height:auto;
border-radius:0.5vw;
text-align: center;
border: 0.1vw solid var(--cinza205);
margin-top: 2vw;
margin-bottom: 2.6vw;
padding-top:0.5vw;
padding-bottom:0.5vw;
padding-left:1vw;
padding-right:1vw;
}
.pages-Contact-destaque-titulo{
font-weight: 300;
font-size: 1vw;
line-height:1.2vw;
}
.pages-Contact-destaque-telefone{
font-weight: 900;
font-size: 1.5vw;
color:var(--azul_itajai02);
}
.pages-Contact-ListContact{
background-color:white;
width: 100%;
border-radius: 0.5vw;
text-align: center;
border: 0.1vw solid var(--cinza205);
}
.pages-Contact-Title{
display: flex;
flex-Direction: row;
background-Color:var(--cinza245);
font-Size: 0.8vw;
font-Weight: 600;
color:var(--cinza100);
padding-Left: 0.8vw;
height: 2.6vw;
border-Top-Left-Radius: 0.5vw;
border-Top-Right-Radius: 0.5vw;
}
.pages-Contact-Title70{
display: flex;
width: 70%;
align-items: center;
justify-content: flex-start;
}
.pages-Contact-Title30{
display: flex;
width: 30%;
align-items: center;
justify-content: center;
}
.pages-Contact-Item{
display: flex;
flex-direction: row;
background-Color:white;
font-size: 0.8vw;
font-weight: 300;
color:var(--cinza100);
padding-left: 0.8vw;
height: 2.6vw;
border-top: 0.1vw solid var(--cinza205);
transition:all 0.2s ease-in-out;
}
.pages-Contact-Item:hover{
background-Color:var(--cinza222);
transition:all 0.2s ease-in-out;
}
.pages-Contact-Item-nome{
display: flex;
width: 70%;
align-items: center;
justify-content: flex-start;
}
.pages-Contact-Item-telefone{
display: flex;
width: 30%;
align-items: center;
justify-content: center;
}
.pages-Contact-box{
width:100%;
display: flex;
flex-wrap:wrap;
justify-content:center;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.pages-Contact-container{
width:90vw;
padding-bottom:15vw;
}
.pages-Contact-destaque{
border-radius:1.5vw;
border-width: 0.3vw;
margin-top:6vw;
margin-bottom:8vw;
padding-top:1.5vw;
padding-bottom:1.5vw;
padding-left:3vw;
padding-right:3vw;
}
.pages-Contact-destaque-titulo{
font-size:6vw;
line-height:8vw;
}
.pages-Contact-destaque-telefone{
font-size:6vw;
}
.pages-Contact-ListContact{
border-radius: 2vw;
text-align: center;
border-width: 0.3vw;
}
.pages-Contact-Title{
font-Size:4vw;
padding-Left:2.4vw;
height:auto;
padding-top:3vw;
padding-bottom:3vw;
border-Top-Left-Radius:2vw;
border-Top-Right-Radius:2vw;
}
.pages-Contact-Title70{
width: 50%;
}
.pages-Contact-Title30{
width: 50%;
}
.pages-Contact-Item{
padding-top:3vw;
padding-bottom:3vw;
font-size:3.5vw;
line-height:5vw;
padding-left:2.4vw;
height:auto;
text-align:left;
border-top-width: 0.3vw;
}
.pages-Contact-Item-nome{
width: 50%;
}
.pages-Contact-Item-telefone{
width: 50%;
}

}
/*End - src/pages/Contact/style.css*/