/*Start - src/components/MapSite/style.css*/
.MapSite{
display: flex;
width: 100%;
background-color:var(--azul_itajai);
justify-content: center;
align-items: center;
padding-top: 3.1vw;
}
.MapSite-container{
width: 60%;
display: flex;
flex-direction: column;
}
.MapSite-topo{
width: 100%;
background-color:var(--teste00);
}
.MapSite-TitleInfo{
color:var(--amarelo_itajai);
font-size: 0.9vw;
line-height:1vw;
font-family:var(--roboto);
font-weight: 700;
text-transform: uppercase;
margin-bottom: 1vw;
}
.MapSite-txt{
font-size: 0.8vw;
font-weight: 400;
font-family:var(--roboto);
color:white;
margin-bottom:0.5vw;
}
.MapSite-txt a{
color:white;
text-decoration:none;
}
.MapSite-txt:hover{
text-decoration:underline;
}
.MapSite-rede svg{
fill:white;
height:1.3vw;
}
.MapSite-rede{
margin-right:1vw;
}
.MapSite-rede.final{
margin-right:0vw;
}
.MapSite-box{
width: 100%;
background-color:var(--teste00);
}
.MapSite-bloco{
width:25%;
background-color:var(--teste00);
float:left;
box-sizing: border-box;
-webkit-box-sizing: border-box;
padding-right:2vw;
margin-bottom:2vw;
}
.MapSite-bloco.dupla{
width:50%;
text-align:center;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.MapSite{
padding-top:13vw;
}
.MapSite-container{
width:90vw;
}
.MapSite-bloco{
width:100%;
padding-right:0vw;
margin-bottom:10vw;
text-align:center;
}
.MapSite-bloco.dupla{
width:100%;
}
.MapSite-TitleInfo{
font-size:6vw;
margin-bottom:3vw;
line-height: 7vw;
}
.MapSite-txt{
font-size:4vw;
margin-bottom:1.5vw;
text-align:center;
}
.MapSite-txt.telefone{
font-size:8vw;
}
.MapSite-rede svg{
height:10vw;
}
.MapSite-rede{
margin-right:7vw;
}
.MapSite-rede.final{
margin-right:0vw;
}

}
/*End - src/components/MapSite/style.css*/