/*Start - src/components/SearchMobile/style.css*/
.SearchMobile{
display: flex;
width: 100%;
align-items: center;
justify-content: center;
}
.SearchMobile-container{
display: flex;
flex-direction: column;
gap: 6.25vw;
background-size: cover;
background-repeat: no-repeat;
width: 93%;
height: 25.2vw;
border-radius: 1.9vw;
align-items: center;
justify-content: center;
}
.SearchMobile-box{
position: absolute;
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
}
.SearchMobile-lente{
display: flex;
flex-direction: column;
width: 93%;
height: 25.2vw;
border-radius: 1.9vw;
justify-content: center;
align-items: center;
gap: 2.1vw;
background-color:var(--azul_lente);
}
.SearchMobile-form{
display: flex;
width: 95%;
border-radius:1.2vw;
height: 9.6vw;
background-color:white;
align-items: center;
overflow:hidden;
}
.SearchMobile-input{
width: 95%;
height: 8.1vw;
border: none;
padding:0vw;
padding-left:3vw;
font-size:4vw;
font-family:var(--roboto);
font-style: normal;
font-weight: 500;
line-height: 3.7vw;
color:var(--azul_itajai02);
box-sizing: border-box;
-webkit-box-sizing: border-box;
}
.SearchMobile-input::placeholder{
color:var(--azul_itajai02);
font-weight: 500;
}
.SearchMobile-button{
width:20vw;
height:100%;
font-weight:inherit;
font-size:inherit;
line-height:inherit;
color:var(--azul_itajai02);
text-align: center;
letter-spacing:inherit;
border:0vw;
background-color:var(--amarelo_itajai);
}
.SearchMobile-button svg{
fill:var(--azul_itajai02);
height:6vw;
}
.SearchMobile-btn-campo{
display: flex;
flex-direction: row;
width: 95%;
align-items: center;
justify-content: space-between;
gap: 3.33vw;
}
.SearchMobile-ButtonMobile{
width: 17.5vw;
height: 7.7vw;
display: flex;
flex-direction: row;
justify-content: center;
border-radius:1vw;
border: 0.1vw solid var(--amarelo_itajai);
font-size: 2.9vw;
color:var(--azul_itajai02);
font-family:var(--roboto);
font-style: normal;
font-weight: 400;
line-height: normal;
background-color:var(--amarelo_itajai);
text-decoration: none;
text-align: center;
align-items: center;
}
/*End - src/components/SearchMobile/style.css*/