/*Start - src/pages/Location/style.css*/
.pages-Location{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-family:var(--roboto);
}
.pages-Location-container{
width: 60%;
display: flex;
flex-direction: row;
justify-content: space-between;
margin-top: 2.4vw;
padding-bottom: 4.2vw;
}
.pages-Location-iframe{
width:31.3vw;
height:23.4vw;
border: 0vw; 
border-radius: 0.3vw; 
}
.pages-Location-dir{
width: 45%;
}
.pages-Location-titulo{
font-family:var(--roboto);
font-weight: 700;
font-size: 1vw;
color:var(--azul_itajai02);
}
.pages-Location-txt{
font-size: 1vw;
font-family:var(--roboto);
font-weight: 300;
margin-bottom:1vw;
}
.pages-Location-separador{
width: 60%;
height:2vw;
display: flex;
background-color:var(--teste00);
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.pages-Location-container{
width:90vw;
margin-top:8vw;
padding-bottom:13vw;
display: block;
}
.pages-Location-iframe{
width:100%;
height:70vw;
border-radius:1vw; 
margin-bottom:5vw;
}
.pages-Location-dir{
width:100%;
}
.pages-Location-titulo{
font-size:5vw;
}
.pages-Location-txt{
font-size:4vw;
margin-bottom:3vw;
}
.pages-Location-separador{
width:90vw;
height:6vw;
}

}
/*End - src/pages/Location/style.css*/