/*Start - src/components/AcessoRapido/style.css*/
.AcessoRapido{
display:flex;
width:100%;
align-items:center;
justify-content:center;
margin-top: 0.5vw;
margin-bottom:0.5vw;
background-color:var(--teste00);
}
.AcessoRapido-container{
width:60%;
background-color:var(--teste00);
position:relative;
display:flex;
flex-wrap:wrap;
justify-content:start;
}
.AcessoRapido-card{
width:24%;
height:7.8vw;
background-color:var(--cardAzul);
float:left;
border-radius:0.3vw;
margin-right:1.33%;
margin-bottom:0.8vw;
overflow:hidden;
background-repeat:no-repeat;
background-position:center center;
background-size:cover;
}
.AcessoRapido-card:nth-child(4n){
margin-right:0%;
}
.AcessoRapido-card a{
width:100%;
height:100%;
display:block;
background-color:var(--teste00);
box-sizing: border-box;
-webkit-box-sizing: border-box;
font-weight:inherit;
font-size:inherit;
line-height:inherit;
color:white;
text-align: inherit;
letter-spacing:inherit;
text-decoration: none;
background-image: linear-gradient(
	to bottom, 
	var(--preto_lente02), 
	var(--preto_lente08)
);
padding-top:0.8vw;
padding-bottom:0.8vw;
padding-left:0.8vw;
padding-right:1.2vw;
transition:all 0.2s ease-in-out;
}
.AcessoRapido-card a:hover{
background-image: linear-gradient(
	to left, 
	var(--preto_lente02), 
	var(--preto_lente04),
	var(--preto_lente06)
);
transition:all 0.2s ease-in-out;
}
.AcessoRapido-card-titulo{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0.5vw;
box-sizing: border-box;
-webkit-box-sizing: border-box;
font-weight:500;
font-size:1.3vw;
line-height:1.4vw;
color:white;
text-align: left;
letter-spacing:0vw;
}
.AcessoRapido-card-txt{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
margin-top:0vw;
margin-bottom:0vw;
font-size:1.1vw;
line-height:1.5vw;
}
.AcessoRapido-card.verde{
background-color:var(--cardVerde);
}
.AcessoRapido-card.retirar_lente a{
background-image: unset;
}
.AcessoRapido-titulo-box{
width:100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color:var(--teste00);
margin-top:1.5vw;
margin-bottom:1.5vw;
}
.AcessoRapido-titulo-container{
width:60%;
background-color:var(--teste00);
}
.AcessoRapido-titulo{
width: 20%;
float:left;
line-height:2vw;
background-color:var(--teste00);
font-family:var(--roboto);
font-style: normal;
font-size: 1.3vw;
font-weight: 700;
color:var(--cinza100);
text-transform: uppercase;
text-align:center;
}
.AcessoRapido-titulo-bar{
width: 40%;
margin-top:1vw;
height:0.1vw;
background-color:var(--fonte);
float:left;
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.AcessoRapido{
width:100%;
margin-top: 1.5vw;
margin-bottom:1.5vw;
}
.AcessoRapido-container{
width:90vw;
}
.AcessoRapido-card{
width:100%;
height:30vw;
border-radius:1.5vw;
margin-right:0%;
margin-bottom:3vw;
}
.AcessoRapido-card a{
padding:3vw;
}
.AcessoRapido-card-titulo{
margin-bottom:1.5vw;
font-size:5vw;
line-height:7vw;
}
.AcessoRapido-card-txt{
font-size:4vw;
line-height:6vw;
}

}
/*End - src/components/AcessoRapido/style.css*/