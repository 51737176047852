/*Start - src/components/TitlePage/styles.css*/
.TitlePage{
width:60%;
display: flex;
flex-direction: column;
margin-top:2.6vw;
}
.TitlePage-iconNews{
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
}
.TitlePage-iconNews-titulo{
color:var(--azul_itajai02);
font-size:1.5vw;
line-height:1.8vw;
font-weight: 300;
font-family:var(--roboto);
font-style: normal;
}
.TitlePage-iconNews-icone{
cursor:pointer;
}
.TitlePage-titulo{
color:var(--azul_itajai02);
font-size:1.5vw;
font-weight: 300;
font-family:var(--roboto);
font-style: normal;
line-height: 1.6vw;
}
.TitlePage-bottom{
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-end;
margin-top: 0.5vw;
margin-bottom: 0.5vw;
}
.TitlePage-bottom-amarelo{
width:5%;
height: 0.2vw;
background-color:var(--amarelo_itajai);
}
.TitlePage-bottom-bar{
width: 95%;
height: 0.1vw;
background-color:var(--cinza222);
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.TitlePage{
width:90vw;
}
.TitlePage-iconNews-titulo{
font-size:5vw;
line-height:7vw;
}
.TitlePage-titulo{
font-size:5vw;
text-transform: uppercase;
line-height:6vw;
}
.TitlePage-bottom{
margin-top: 1.5vw;
margin-bottom: 1.5vw;
}
.TitlePage-bottom-amarelo{
height: 0.6vw;
}
.TitlePage-bottom-bar{
height: 0.3vw;
}
.TitlePage-iconNews-icone{
background-color:var(--teste00);
float:right;
display:block;
width:10vw;
text-align:right;
}
.TitlePage-iconNews-icone svg{
fill:var(--cinza100);
}

}
/*End - src/components/TitlePage/styles.css*/