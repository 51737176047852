/*Start - src/components/NewsShare/style.css*/
.NewsShare{
width:100%;
height:auto;
background-color:var(--teste00);
float:left;
padding-bottom:1vw;
}
.NewsShare-campo{
width:100%;
height:3vw;
background-color:white;
float:left;
border-radius:0.3vw;
padding-left:1vw;
padding-right:1vw;
box-shadow: 0vw 0.1vw 0.3vw rgba(0,0,0,0.1);
}
.NewsShare-titulo{
width:auto;
height:100%;
background-color:var(--teste00);
float:left;
margin-top: 0vw;
margin-right: 1vw;
font-weight:600;
font-size:0.8vw;
line-height:3vw;
color:var(--fonte);
text-align: left;
letter-spacing:0vw;
text-transform: uppercase;
cursor:default;
}
.NewsShare-btn{
width:2vw;
height:2vw;
background-color:var(--preto_lente07);
float:left;
margin-top: 0.5vw;
margin-right:0.5vw;
line-height:1.8vw;
text-align: center;
border-radius:5vw;
cursor:default;
}
.NewsShare-btn a{
display:block;
}
.NewsShare-btn svg{
fill:white;
height:1.2vw;
}
.NewsShare-btn span{
color:white;
font-size:1.2vw;
line-height:2vw;
}
.NewsShare-btn.facebook{
background-color:var(--facebook);
}
.NewsShare-btn.instagram{
background-color:var(--instagram);
}
.NewsShare-btn.twitter{
background-color:var(--twitter);
}
.NewsShare-btn.whatsapp{
background-color:var(--whatsapp);
}

@media only screen and (max-width:1024px) and (orientation: portrait){

.NewsShare{
margin-bottom:3vw;
}
.NewsShare-campo{
height:auto;
border-radius:1vw;
padding-left:3vw;
padding-right:3vw;
padding-bottom:3vw;
box-shadow: 0vw 0.3vw 1vw rgba(0,0,0,0.1);
}
.NewsShare-titulo{
width:100%;
margin-right: 0vw;
font-size:4vw;
line-height:15vw;
}
.NewsShare-btn{
width:12vw;
height:12vw;
line-height:12vw;
margin-top: 3vw;
margin-right:3vw;
border-radius:15vw;
}
.NewsShare-btn svg{
height:7.2vw;
}
.NewsShare-btn span{
font-size:7.2vw;
line-height:12vw;
}

}
/*End - src/components/NewsShare/style.css*/